import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as router from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import DefaultHeader from './DefaultHeader';
import DefaultAside from './DefaultAside';

import {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppBreadcrumb2 as AppBreadcrumb,
    AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';

import ErrorModal from '../../../components/modals/ErrorModal';
import DefaultSpinner from '../../../components/loaders/DefaultSpinner';
import { adminNavRoutes, appBreadcrumbRoutes } from '../../../config';

import { adminRoutes } from '../../../routes/_adminRoutes';

import { closeErrorModal } from '../../../redux/methods/generic';

import styles from './index.module.scss';

const propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    errorAdditionalData: PropTypes.object,
    errorForceLogout: PropTypes.bool,
    closeErrorModal: PropTypes.func,
    children: PropTypes.node,
    isShopCategoryActive: PropTypes.bool,
};

const DefaultLayout = ({ isLoading, error, errorAdditionalData, errorForceLogout, closeErrorModal, children, isShopCategoryActive, isConnectedToERP, ...rest}) => {
    const intl = useIntl();
    const translations = intl.messages.defaultLayout;
    const history = router.useHistory();

    const disabledSectionsIds = [];
    if(!isShopCategoryActive || !isConnectedToERP){
        disabledSectionsIds.push('ROUTE_ASSORTMENT_PRODUCTS_CATEGORY_ASSIGN');
    }

    const dropdownSidebarItems = [ ...document.getElementsByClassName('nav-item nav-dropdown') ];

    const reset = () => {
        const input = document.getElementById('navSearchText');

        input.value = '';

        const nav = document.getElementsByClassName('sidebar-nav');

        if(nav.length === 0){
            return;
        }

        const firstNav = nav[0];

        const navItems = [...firstNav.getElementsByClassName('nav-item')];

        navItems.forEach(item => {
            item.classList.remove('open');
            item.classList.remove('found');
            item.classList.remove('hidden');
        });

        const navTitles = [...firstNav.getElementsByClassName('nav-title')];

        navTitles.forEach(title => {
            title.classList.remove('hidden');
        });
    };

    const search = (v) => {
        const nav = document.getElementsByClassName('sidebar-nav');

        if (v === '' || nav.length === 0) {
            reset();
            return;
        }

        const searchText = v.toLowerCase();

        const firstNav = nav[0];

        // wszystkie pozycje menu
        const navItems = [...firstNav.getElementsByClassName('nav-item')];

        // wszystkie pozycje rozwijane
        const navDropdowns = [...firstNav.getElementsByClassName('nav-item nav-dropdown')];

        // wyczyszczenie znaczników poprzedniego wyszukiwania i zwinięcie wszystkich menu
        navItems.forEach(item => {
            item.classList.remove('open');
            item.classList.remove('found');
            item.classList.remove('hidden');
        });

        // zamknięcie wszystkich pozycji rozwijanych
        navDropdowns.forEach(item => item.classList.remove('open'));

        navItems.forEach(item => {
            if (item.getElementsByTagName('A')[0].innerText.toLowerCase().indexOf(searchText) > -1) {
                item.classList.add('found');

                // jeśli znaleziony element jest w menu rozwijanym, to otwieramy to menu
                if (item.parentElement.classList.contains('nav-dropdown-items')) {
                    if (!item.parentElement.parentElement.classList.contains('open')) {
                        item.parentElement.parentElement.classList.add('open');
                    }
                }
            }
        });

        navDropdowns.forEach(item => {
            if (!item.classList.contains('open') && !item.classList.contains('found')) {
                item.classList.add('hidden');
            }
        });

        navItems.filter(item => !item.classList.contains('nav-dropdown')).forEach(item => {
            if (!item.classList.contains('found')) {
                const insideFoundDropdown =
                    item.parentElement.classList.contains('nav-dropdown-items') &&
                    item.parentElement.parentElement.classList.contains('found');

                if (!insideFoundDropdown) {
                    item.classList.add('hidden');
                }
            }
        });

        const navTitles = [...firstNav.getElementsByClassName('nav-title')];

        navTitles.forEach(title => {
            let next = title.nextSibling;
            while(next && !next.classList.contains('nav-title')) {
                if (!next.classList.contains('hidden')) {
                    title.classList.remove('hidden');
                    return;
                }
                next = next.nextSibling;
            }
            title.classList.add('hidden');
        });

        firstNav.scrollTo(0,0);
    };

    //func to close dropdown item when open another
    const openClassToggleHandler = (e) => {
        if(e.target.classList.contains('nav-dropdown-toggle')) {
            if(e.target.parentElement.classList.contains('open')) {
                dropdownSidebarItems.forEach(element => element.classList.remove('open'));
                e.target.parentElement.classList.add('open');
            }
            else {
                e.target.parentElement.classList.remove('open');
            }
        }
        else if (e.target.classList.contains('nav-link') && !e.target.closest('.nav-dropdown')) {
            dropdownSidebarItems.forEach(element => element.classList.remove('open'));
        }
    };

	const getFullRouteName = (routes = adminRoutes, path = '') => {
		for(const key in routes) {
			let name = translations.appSidebar.positions[routes[key].id];

            if (routes[key].path === history.location.pathname) {
				return path + name;
            }

			if (routes[key].children) {
				name = getFullRouteName(routes[key].children, (path === '' ? '' : path + ' / ') + name + ' / ');
				if (name !== null) {
					return name;
                }
			}
		}
		return null;
	};

    useEffect(() => {
        const title = getFullRouteName();

        const additionalInfo = ` - ${window.location.hostname}`;

        document.title = (title || 'Connectico') + additionalInfo;

        return () => {
            document.title = 'Connectico' + additionalInfo;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ window.location.href ]);

    return (
        <div className="app">
            <AppHeader fixed>
                <DefaultHeader />
            </AppHeader>
            <div className="app-body">
                <AppSidebar fixed display="lg">
                    <AppSidebarHeader />
                    <AppSidebarForm />

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <input style={{
                            margin: '5px',
                            padding: '2px 5px',
                            backgroundColor: 'transparent',
                            color: 'white',
                            border: '1px solid gray',
                            width: '70%',
                            borderRadius: '6px'
                        }} id="navSearchText" type="text" placeholder="szukaj pozycji menu" onChange={(e) => { search(e.target.value); }}>
                        </input>
                        <i className="fa fa-search" style={{
                            fontSize: '18px',
                            color: 'gray'
                        }}/>
                        <i className="fa fa-remove" style={{
                            fontSize: '18px',
                            color: 'gray',
                            marginLeft: '10px',
                            visibility: 'visible',
                            cursor: 'pointer'
                        }} onClick={() => { search(''); }}/>
                    </div>

                    <AppSidebarNav
                        navConfig={adminNavRoutes(translations.appSidebar, disabledSectionsIds, '')}
                        computedmatch={rest.coumputedMatch}
                        location={rest.location}
                        router={router}
                        onClick={openClassToggleHandler}
                    />
                    <AppSidebarFooter />
                    <AppSidebarMinimizer />
                </AppSidebar>
                <main className="main position-relative">
                    <AppBreadcrumb
                        /** This key is a fix to breadcrumbs not reloading on locale update */
                        key={`defaultLayout-${intl.locale}`}
                        appRoutes={appBreadcrumbRoutes(translations.appSidebar)}
                        router={router}
                        className={styles.breadcrumbs}
                    />
                    { children }
                    <DefaultSpinner isLoading={isLoading} />
                </main>
                <aside>
                    <AppAside fixed>
                        <DefaultAside />
                    </AppAside>
                </aside>
            </div>
            <AppFooter>
                Copyright (©) - Connectico 2021
            </AppFooter>
            <ErrorModal
                error={error}
                errorAdditionalData={errorAdditionalData}
                errorForceLogout={errorForceLogout}
                closeErrorModal={closeErrorModal}
                buttonLabel={translations.appGlobal.errorModal.close}
                header={translations.appGlobal.errorModal.header}
            />
        </div>
    );
};

DefaultLayout.propTypes = propTypes;

const mapStateToProps = state => ({
    isLoading: state.loaders.global,
    error: state.errors.global,
    errorAdditionalData: state.errors.additionalData,
    errorForceLogout: state.errors.forceLogout,
    isShopCategoryActive: state.appInit.isShopCategoryActive,
    isConnectedToERP: state.appInit.isConnectedToERP,
});

const mapDispatch = {
    closeErrorModal
};

export default connect(mapStateToProps, mapDispatch)(DefaultLayout);