import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import authReducer, { initialState as authInitialState } from '../features/auth/slice';
import localeReducer, { initialState as localeInitialState } from '../features/locale/slice';
import categoriesReducer, { initialState as categoriesInitialState } from '../features/categories/slice';
import productsReducer, { initialState as productsInitialState } from '../features/products/slice';
import storeLocaleReducer, { initialState as storeLocaleInitialState } from '../features/storeLocale/slice';
import appInitReducer, { initialState as appInitInitialState } from '../features/appInit/slice';
import persistedReducer from '../features/persisted/slice';
import { initialState as persistedLocaleInitialState } from '../features/persisted/persistedLocale/slice';
import notificationsReducer, { initialState as notificationsInitialState } from '../features/notifications/slice';
import discountsReducer, { initialState as discountsInitialState } from '../features/discounts/slice';
import loadersReducer, { initialState as loadersInitialState } from '../features/loaders/slice';
import settingTabsReducer, { initialState as settingTabsInitialState } from '../features/settingTabs/slice';
import errorsReducer, { initialState as errorsInitialState } from '../features/errors/slice';
import refetchersReducer, { initialState as refetchersInitialState } from '../features/refetchers/slice';
import toastReducer, { initialState as toastInitialState } from '../features/toast/slice';
import fontsReducer, { initialState as fontsInitialState } from '../features/fonts/slice';
import visualSettingsReducer, { initialState as visualSettingsInitialState } from '../features/visualSettings/slice';
import searchConfigsReducer, { initialState as searchConfigsInitialState } from '../features/searchConfigs/slice';

const initialState = {
    auth: authInitialState,
    locale: localeInitialState,
    storeLocale: storeLocaleInitialState,
    categories: categoriesInitialState,
    products: productsInitialState,
    appInit: appInitInitialState,
    persisted: {
        persistedLocale: persistedLocaleInitialState,
    },
    discounts: discountsInitialState,
    loaders: loadersInitialState,
    settingTabs: settingTabsInitialState,
    errors: errorsInitialState,
    refetchers: refetchersInitialState,
    notifications: notificationsInitialState,
    toast: toastInitialState,
    fonts: fontsInitialState,
    visualSettings: visualSettingsInitialState,
    searchConfigs: searchConfigsInitialState
};

const rootReducer = combineReducers({
    auth: authReducer,
    locale: localeReducer,
    storeLocale: storeLocaleReducer,
    categories: categoriesReducer,
    products: productsReducer,
    appInit: appInitReducer,
    persisted: persistedReducer,
    discounts: discountsReducer,
    loaders: loadersReducer,
    settingTabs: settingTabsReducer,
    errors: errorsReducer,
    refetchers: refetchersReducer,
    notifications: notificationsReducer,
    toast: toastReducer,
    fonts: fontsReducer,
    visualSettings: visualSettingsReducer,
    searchConfigs: searchConfigsReducer
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['persisted']
};

export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: initialState,
    middleware: [thunk]
});

export const persistor = persistStore(store);