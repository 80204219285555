import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { TabContent, Col, Row } from 'reactstrap';
import TinyMCEEditor from '../../TinyMCEEditor';
import TabulatorNav from '../../TabulatorNav';

import { formUtilsHocPropTypes } from '../../../propTypes';
import { Field } from 'react-final-form';

const propTypes = {
    form: PropTypes.object.isRequired,
    utils: formUtilsHocPropTypes.isRequired,
    propertyName: PropTypes.string.isRequired,
    header: PropTypes.string,
    className: PropTypes.string,
    tinyMCEButtonGenerator: PropTypes.func,
    customSettings: PropTypes.object,
    charControlHandler: PropTypes.func,
};

const FormFieldTranslationsQuill = ({ form, utils, propertyName, header, className, tinyMCEButtonGenerator, customSettings, customStyles, charControlHandler }) => {
    const initialValuesFromForm = form.getState().initialValues;

    const translationsInputs = Object.keys(initialValuesFromForm)
        .reduce((acc, curr) => curr.includes(propertyName + '-') ? { ...acc, [curr]: initialValuesFromForm[curr] } : acc, {});

    const [activeLanguageTab, setActiveLanguageTab] = useState(utils.currentLanguage());

    return (
        <div className={clsx('w-100', className)}>
            <p className="text-muted mb-1">{header}</p>
            <TabulatorNav
                setActiveTab={setActiveLanguageTab}
                activeTab={activeLanguageTab}
                tabsList={utils.allLanguagesDetails().map(language => ({iso: language.iso.toLowerCase(), label: language.nativeName}))}
            />
            <TabContent>
                <Row>
                    <Col className="col-12">
                        {
                            Object.keys(translationsInputs)
                                .map(key => {
                                    return (
                                        <div className='w-100' key={key} style={{display: (key === propertyName + '-' + (activeLanguageTab[0].toUpperCase()+activeLanguageTab[1].toLowerCase()) + '-translations' ? 'block':'none')}}>
                                            <Field name={key}>
                                                {
                                                    props =>
                                                        <TinyMCEEditor
                                                            key={`${props.input.name}`}
                                                            {...props.input}
                                                            generateCustomButtons={tinyMCEButtonGenerator}
                                                            customSettings={customSettings}
                                                            customStyles={customStyles}
                                                            charControlHandler={charControlHandler}
                                                        />
                                                }
                                            </Field>
                                        </div>
                                    );
                                })
                        }
                    </Col>
                </Row>
            </TabContent>
        </div>
    );
};

FormFieldTranslationsQuill.propTypes = propTypes;

export default FormFieldTranslationsQuill;
