import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Container, Form, Row, Col } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import { getGeneralSettings, updateGeneralSettings } from '../../../redux/methods/extrasSettings';

import useFetch from '../../../hooks/useFetch';

import ContainerWithCard from '../../../components/ContainerWithCard';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';
import FormFieldTextOrNumber from '../../../components/formFields/FormFieldTextOrNumber';
import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';

import { categoryMenuTypeEnum } from '../../../enums/generalSettings';
import { generateDropDownOptionsForEnum } from '../../../utils/components';

const propTypes = {
    getGeneralSettings: PropTypes.func,
    updateGeneralSettings: PropTypes.func
};

const ExtrasGeneralSettings = ({
    getGeneralSettings,
    updateGeneralSettings
}) => {
    const intl = useIntl();
    const translations = intl.messages.generalSettings;

    const translationsType = translations.categoryMenuTypes;

    const [ data ] = useFetch(getGeneralSettings);

    const categoryMenuType = generateDropDownOptionsForEnum(categoryMenuTypeEnum, translationsType, false);

    return (
        <ContainerWithCard title={translations.title} iconClass='fa fa-cogs'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateGeneralSettings}
                render={({ handleSubmit, values, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid>
                                <Row className='my-2'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.twoLineNavbar.label} name='twoLineNavbar' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.twoLineNavbar.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.bigNavbar.label} name='bigNavbar' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.bigNavbar.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.languagePickerInNavbar.label} name='languagePickerInNavbar' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.languagePickerInNavbar.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.addFreeShippingMethodToCart.label} name='addFreeShippingMethodToCart' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.addFreeShippingMethodToCart.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.carouselsInfiniteScroll.label} name='carouselsInfiniteScroll' />
                                    </Col>
                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.carouselsInfiniteScroll.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.autoAddProductPromotionTag.label} name='autoAddProductPromotionTag' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.autoAddProductPromotionTag.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <hr className='my-3' />

                                <Row className='mb-2 align-items-center'>
                                    <Col md="auto">
                                        <FormFieldSwitchPill label={translations.autoAddProductNewTag.label} name='autoAddProductNewTag' />
                                    </Col>
                                    <Col md="auto">
                                        <FormFieldTextOrNumber
                                            label={translations.productIsNewNumberOfDays.label}
                                            name='productIsNewNumberOfDays'
                                            type='number'
                                            min={1}
                                            disabled={!values.autoAddProductNewTag}
                                        />
                                    </Col>

                                    <Col md="12" className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.autoAddProductNewTag.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.disableOrderingAsGuest.label} name='disableOrderingAsGuest' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.disableOrderingAsGuest.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md="auto">
                                        <FormFieldWithDropdown
                                            label={translations.categoryMenuType}
                                            name='categoryMenuType'
                                            options={categoryMenuType}
                                        />
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.notHideSubmenuAfterMouseLeave.label} name='notHideSubmenuAfterMouseLeave' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.notHideSubmenuAfterMouseLeave.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-2'>
                                    <Col>
                                        <FormFieldSwitchPill label={translations.preventAutoCheckAgreements.label} name='preventAutoCheckAgreements' />
                                    </Col>

                                    <Col md='12' className='ml-2'>
                                        <div className='ml-5'>
                                            <small>{translations.preventAutoCheckAgreements.explanation}</small>
                                        </div>
                                    </Col>
                                </Row>

                                <SectionEditFormButtons hideCancelButton={true} formReset={form.reset}/>
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    );
};

const mapDispatch = {
    getGeneralSettings,
    updateGeneralSettings
};

ExtrasGeneralSettings.propTypes = propTypes;

export default withRouter(connect(null, mapDispatch)(ExtrasGeneralSettings));