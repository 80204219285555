import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import { addUpdatePageSection } from '../../../../redux/methods/homePage';
import { getAttributeDictionaryValues } from '../../../../redux/methods/attributes';
import { getManufacturers } from '../../../../redux/methods/manufacturers';
import { getTranslationForIso } from '../../../../utils/components';

import useFetch from '../../../../hooks/useFetch';

import { Form as FinalForm } from 'react-final-form';
import { Form, Row, Col, Button, Label } from 'reactstrap';

import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import FormFieldTranslationsQuill from '../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import HomePageSectionMainData from '../HomePageSectionMainData';
import CategorySelectModal from '../../../../components/modals/CategorySelectModal';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import styles from './index.module.scss';
import { withCategories } from '../../../../hoc/withCategories';
import { compose } from '@reduxjs/toolkit';
import FormFieldWithCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';

import Tabulator from '../../../../components/Tabulator';

const propTypes = {
    currentLanguage: PropTypes.string,
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object,
};

const ArticlesWithBookmarksSection = ({
    utils,
    section,
    addUpdatePageSection,
    getAttributeDictionaryValues,
    getManufacturers,
    categories,
    isProductCard,
    sectionLocation
}) => {
    const history = useHistory();
    const intl = useIntl();

    const translations = intl.messages.homePage.promoSections.sections;
    const translationsHomePage = intl.messages.homePage;

    const [ selectedCategory, setSelectedCategory ] = useState(section.categoryId);
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const [ manufacturers ] = useFetch(getManufacturers);
    const tagId = 1;
    const [ tags ] = useFetch(getAttributeDictionaryValues, tagId);

    const config = { translations: ['content', 'button', 'title'], date: ['activeFrom', 'activeTo'] };

    const openModal = () => setIsModalOpen(true);

    const removeCategory = () => setSelectedCategory(null);

    useEffect(() => {
        if(section) {
            setSelectedCategory(section.categoryId);
        }
    }, [ section ]); // eslint-disable-line

    const handleOnSubmit = async formData => {
        const objectToSend = {
            ...formData,
            categoryId: formData.isProductCategory ? -1 : selectedCategory,
            manufacturerId: formData.isProductManufacturer ? -1 : formData.manufacturerId
        };

        const response = await addUpdatePageSection(utils.generateFormToSubmitValues(objectToSend, config));

        if(typeof response === 'number') {
            history.push(`edit?sectionID=${response}&sectionType=${section.type}&location=${sectionLocation}`);

            return;
        }

        return response;
    };

    const generateDropdownOptions = (list, emptyTranslation, idSelector = 'id') => {
        return ([
            {
                id: null,
                label: emptyTranslation
            },
            ...list.map(elem => ({
                id: elem[idSelector],
                label: typeof elem.name === 'object' ? getTranslationForIso(elem.name, utils.currentLanguage()) : elem.name
            }))
        ]);
    };

    const manufacturersOptions = manufacturers ? generateDropdownOptions(manufacturers, translations.inputs.selectNone) : [];
    const tagOptions = tags ? generateDropdownOptions(tags, translations.inputs.selectNone, 'attributeDictionaryId') : [];

    return section && tags && manufacturers && categories ? (
        <div className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(
                        {
                            ...section,
                            isProductCategory: section.categoryId === -1,
                            isProductManufacturer: section.manufacturerId === -1
                        }, config
                    )}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        const currentManufacturerSelection = manufacturersOptions.find(option => option.id === values.manufacturerId);
                        const currentTagSelection = tagOptions.find(option => option.id === values.tagId);
                        const currentCategoryLabel = categories.find(category => category.id === selectedCategory);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translationsHomePage.tabs.basicData,
                                            id: 'searchTerms-table',
                                            component: <HomePageSectionMainData
                                                isSectionHeightController={false}
                                                isNumberOfTilesController={true}
                                                form={form} />
                                        },
                                        {
                                            label: translationsHomePage.tabs.settings,
                                            id: 'lastPhrases-table',
                                            component: <React.Fragment>
                                                <Row>
                                                    <Col>
                                                        <FormFieldWithCheckbox
                                                            isDisabled={!isProductCard}
                                                            name='isProductCategory'
                                                            label={translations.inputs.searchInProductCategory}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormFieldWithCheckbox
                                                            isDisabled={!isProductCard}
                                                            name='isProductManufacturer'
                                                            label={translations.inputs.searchInProductManufacturer}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-3 flex-column d-flex'>
                                                    <Col>
                                                        <Label>{translations.inputs.category}</Label>
                                                        <div>
                                                            <Button
                                                                disabled={values.isProductCategory}
                                                                onClick={openModal}
                                                                color={selectedCategory ? 'warning' : 'primary'}
                                                            >
                                                                {currentCategoryLabel ? currentCategoryLabel.name : translations.inputs.selectCategory}
                                                            </Button>
                                                            <Button
                                                                className='ml-1'
                                                                onClick={removeCategory}
                                                                color='danger'
                                                            >
                                                                <i className="fa fa-times" />
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-3'>
                                                    <Col>
                                                        <FormFieldWithDropdown
                                                                isDisabled={values.isProductManufacturer}
                                                                name='manufacturerId'
                                                                label={translations.inputs.manufacturer}
                                                                options={manufacturersOptions}
                                                                currentSelectionLabel={currentManufacturerSelection ? currentManufacturerSelection.label : translations.inputs.selectNone}
                                                            />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-3'>
                                                    <Col>
                                                        <FormFieldWithDropdown
                                                                name='tagId'
                                                                label={translations.inputs.tag}
                                                                options={tagOptions}
                                                                currentSelectionLabel={currentTagSelection ? currentTagSelection.label :  translations.inputs.selectNone}
                                                            />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col>
                                                        <FormFieldTranslationsQuill
                                                                form={form}
                                                                utils={utils}
                                                                propertyName="content"
                                                                header={translations.inputs.content}
                                                            />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        },
                                    ]}
                                />
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.button,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        }
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
            <CategorySelectModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                caption={translations.inputs.selectCategory}
                categorySelect={setSelectedCategory}
            />
        </div>
    ) : null;
};

ArticlesWithBookmarksSection.propTypes = propTypes;

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state),
});

const mapDispatch = {
    addUpdatePageSection,
    getAttributeDictionaryValues,
    getManufacturers,
};

const enhance = compose(
    withCategories,
    withFormUtils,
    connect(mapStateToProps, mapDispatch)
);

export default enhance(ArticlesWithBookmarksSection);