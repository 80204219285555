const attributes = {
    view: {
        title: 'Lista atrubytów',
        paginator: {
            previous: 'Poprzednia',
            next: 'Następna'
        },
        actions: {
            addAttribute: 'DODAJ ATRYBUT'
        },
        additionModal: {
            header: 'Dodaj nowy atrybut',
            body: 'Czy chcesz utworzyć nowy atrybut?',
            confirm: 'DODAJ',
            cancel: 'WRÓĆ'
        }
    },
    searchForm: {
        phrase: {
            label: 'Nazwa atrybutu:',
            placeholder: ''
        },
        type: {
            label: 'Typ atrybutu:',
            placeholder: 'dowolny'
        },
        presentation: {
            label: 'Sposób prezentacji:',
            placeholder: 'dowolny'
        },
        filters: {
            attributeType: {
                placeholder: 'dowolny',
                tag: 'Tag',
                dictionary: 'Słownik',
                int: 'Zakres liczbowy',
                decimal: 'Zakres zmiennoprzecinkowy',
                dateTime: 'Zakres dat',
                color: 'Kolor',
                boolean: 'Jedna z wartości: tak/nie',
            },
            attributePresentation: {
                placeholder: 'dowolny',
                tag: 'Chmura tagów',
                color: 'Kolor',
                simpleDictionary: 'Słownik prosty',
                searchableDictionary: 'Słownik rozszerzony',
                range: 'Zakres',
                boolean: 'Checkbox',
            }
        },
        action: 'SZUKAJ',
        reset: 'RESETUJ',
    },
    table: {
        headers: {
            name: 'Nazwa atrybutu',
            categories: 'Kategoria',
            type: 'Typ atrybutu',
            presentation: 'Sposób prezentacji',
            actions: 'Akcje'
        },
        deleteModal: {
            header: 'Atrybut:',
            body: 'Czy jestes pewien, że chcesz usunąć atrybut',
            confirm: 'USUŃ',
            cancel: 'WRÓĆ'
        },
        tooltips: {
            edit: 'Edytuj atrybut',
            remove: 'Usuń atrybut',
            add: 'Dodaj atrybut'
        },
        empty: 'Nie znaleziono żadnych atrybutów',
        nameFiller: '[BRAK NAZWY]',
        errorModalText: 'Wystąpił błąd podczas usuwania atrybutu'
    },
    edit: {
        nameFiller: '[BRAK NAZWY]',
        tabSelectorTitle: 'Sekcje',
        tabs: {
            basic: {
                title: 'Dane podstawowe',
                inputs: {
                    name: 'Nazwa',
                    nameExtension: 'Rozszerzenie nazwy',
                    type: 'Typ',
                    presentationType: 'Sposób prezentacji',
                    isRequired: 'Jest wymagany',
                    isRequiredTooltip: 'Wartość musi zostać określona dla wszystkich produktów w katalogu produktów',
                    isFilter: 'Jest filtrem',
                    isFilterTooltip: 'Ten atrybut zostanie wyeksponowany w sklepie jako filtr, po którym użytkownicy mogą wyszukiwać produkty',
                    isMultiselect: 'Możliwość wielokrotnego wyboru',
                    isMultiselectTooltip: 'Każdy produkt może mieć więcej określonych wartości dla tego atrybutu niż jedna',
                    isMappingWaproMag: 'Mapuj z pola dodatkowego artykułu WAPRO Mag',
                    isMappingWaproMagTooltip: 'Wybierz pole dodatkowe z systemu WAPRO Mag i mapuj przynależne atrybuty',
                    resetCategoryAfterChange: 'Resetuj bieżącą kategorię po zmianie wartości filtra',
                    resetPhraseAfterChange: 'Resetuj bieżącą frazę wyszukiwania po zmianie wartości filtra',
                },
                editModalHeader: 'Nazwa cechy',
                waproMagDropdown: {
                    label: 'Dostępne pola dodatkowe',
                    defaultSelection: 'Dowolne'
                },
                explanations: {
                    resetCategoryAfterChangeTrue: '* jeśli ten atrybut jest filtrem, po każdej zmianie wartości filtra bieżąca kategoria zostanie zresetowana - wyszukiwanie odbędzie się we wszystkich kategoriach',
                    resetCategoryAfterChangeFalse: '* jeśli ten atrybut jest filtrem, po każdej zmianie wartości filtra bieżąca kategoria nie zostanie zresetowana - wyszukiwanie odbędzie się w aktualnie wybranej kategorii',
                    resetCategoryAfterChangeNull: '* jeśli ten atrybut jest filtrem, o tym czy po każdej zmianie wartości filtra bieżąca kategoria zostanie zresetowana - wyszukiwanie odbędzie się we wszystkich lub w aktualnie wybranej kategorii, zdecydują ustawienia globalne (sekcja Asortyment -> Wyszukiwarka i filtry)',

                    resetPhraseAfterChangeTrue: '* jeśli ten atrybut jest filtrem, po każdej zmianie wartości filtra bieżąca fraza wyszykiwania zostanie zresetowana',
                    resetPhraseAfterChangeFalse: '* jeśli ten atrybut jest filtrem, po każdej zmianie wartości filtra bieżąca fraza wyszykiwania nie zostanie zresetowana',
                    resetPhraseAfterChangeNull: '* jeśli ten atrybut jest filtrem, o tym czy po każdej zmianie wartości filtra bieżąca fraza zostanie zresetowana, zdecydują ustawienia globalne (sekcja Asortyment -> Wyszukiwarka i filtry)'
                },
                status: 'Status:',
                statusLabels: {
                    editing: "Nieaktywny",
                    published: "Aktywny"
                }
            },
            values: {
                title: 'Wartości',
                colors: {
                    columnNames: {
                        color: 'Kolor',
                        name: 'Nazwa',
                        actions: 'Akcje',
                    },
                    actions: {
                        edit: 'Edytuj kolor',
                        remove: 'Usuń kolor',
                        addColor: 'DODAJ KOLOR'
                    },
                    deleteModal: {
                        header: 'Kolor:',
                        body: 'Czy jestes pewien, że chcesz usunąć kolor',
                        confirm: 'USUŃ',
                        cancel: 'WRÓĆ'
                    },
                    additionModal: {
                        header: 'Dodaj nowy kolor',
                        body: 'Czy chcesz utworzyć nowy kolor?',
                        confirm: 'DODAJ',
                        cancel: 'WRÓĆ'
                    },
                    empty: {
                        table: 'Nie znaleziono żadnych kolorów dla atrybutu',
                        name: '[BRAK NAZWY]'
                    }
                },
                dictionaryValues: {
                    columnNames: {
                        name: 'Nazwa',
                        actions: 'Akcje',
                        backgroundColor: 'Kolor tła',
                        borderColor: 'Kolor ramki',
                        textColor: 'Kolor tekstu',
                        active: 'Aktywna',
                        mappingField: 'Zmapowane pole',
                        mappingValue: 'Zmapowana wartość'
                    },
                    actions: {
                        edit: 'Edytuj wartość',
                        remove: 'Usuń wartość',
                        addColor: 'DODAJ WARTOŚĆ'
                    },
                    deleteModal: {
                        header: 'Wartość:',
                        body: 'Czy jestes pewien, że chcesz usunąć wartość',
                        confirm: 'USUŃ',
                        cancel: 'WRÓĆ'
                    },
                    additionModal: {
                        header: 'Dodaj nową wartość',
                        body: 'Czy chcesz utworzyć nową wartość?',
                        confirm: 'DODAJ',
                        cancel: 'WRÓĆ'
                    },
                    empty: {
                        table: 'Nie znaleziono żadnych wartości dla atrybutu',
                        name: '[BRAK NAZWY]'
                    },
                    inputs: {
                        name: 'Nazwa wartości'
                    }
                },
                dateRange: {
                    startDateLabel: 'Data minimalna',
                    endDateLabel: 'Data maksymalna'
                },
                range: {
                    minLabel: 'Limit dolny',
                    maxLabel: 'Limit górny',
                    precisionLabel: 'Precyzja'
                }
            },
            settings: {
                title: 'Ustawienia',
                inputs: {
                    isExpanded: 'Jest permanentnie rozwinięty',
                    isExpandedTooltip: 'Parametr określający, czy atrybut znajdujący się w menu bocznym domyślnie będzie będzie rozwinięty',
                    foldedDictionaryItemsVisible: 'Liczba rozwiniętych wartości filtra',
                    searchFieldPlaceholder: 'Placeholder frazy wyszukiwania',
                    minDateLabel: 'Data minimalna',
                    maxDateLabel: 'Data maksymalna',
                    minNumberLabel: 'Limit dolny',
                    maxNumberLabel: 'Limit górny',
                    precisionLabel: 'Precyzja',
                    startDateLabel: 'Data minimalna',
                    endDateLabel: 'Data maksymalna'
                },
                empty: 'Nie można zmienić żadnych ustawień dla obecnego typu atrybutu',
                editModalHeader: 'Placeholdery frazy wyszukiwania'
            }
        }
    }
};

export default attributes;