import auth from './auth';
import locale from './locale';
import defaultLayout from './defaultLayout';
import categories from './categories';
import features from './features';
import attributes from './attributes';
import storeLocale from './storeLocale';
import sharedComponents from './sharedComponents';
import products from './products';
import manufacturers from './manufacturers';
import agreements from './agreements';
import currencies from './currencies';
import countries from './countries';
import regions from './regions';
import exchangeRates from './exchangeRates';
import discounts from './discounts';
import customPages from './customPages';
import orders from './orders';
import users from './users';
import groups from './groups';
import dashboard from './dashboard';
import list from './list';
import general from './generalProductSettings';
import tile from './tile';
import card from './card';
import catalog from './catalog';
import banner from './banner';
import animatedBanner from './animatedBanner';
import homePage from './homePage';
import quantity from './quantity';
import visualSettings from './visualSettings';
import emails from './emails';
import promotionBar from './promotionBar';
import searchEngine from './searchEngine';
import integrations from './integrations';
import synchronizations from './synchronizations';
import menuSettings from './menuSettings';
import technicalBreak from './technicalBreak';
import notFound from './notFound';
import notifications from './notifications';
import units from './units';
import predefinedSearch from './predefinedSearch';
import accountSettings from './accountSettings';
import fonts from './fonts';
import seo from './seo';
import cookiesAgreements from './cookiesAgreements';
import global from './global';
import mediaLibrary from './mediaLibrary';
import adBanners from './adBanners';
import report from './report';
import translations from './translations';
import taxRates from './taxRates';
import orderStatuses from './orderStatuses';
import languages from './languages';
import delivery from './delivery';
import loginSettings from './loginSettings';
import sections from './sections';
import shippingForms from './shippingForms';
import paymentForms from './paymentForms';
import services from './services';
import generalSettings from './generalSettings';
import productsDescriptionsImport from './productsDescriptionsImport';

const pl = {
    auth,
    locale,
    defaultLayout,
    categories,
    features,
    attributes,
    storeLocale,
    sharedComponents,
    products,
    manufacturers,
    agreements,
    currencies,
    countries,
    regions,
    exchangeRates,
    discounts,
    customPages,
    orders,
    users,
    groups,
    dashboard,
    list,
    general,
    tile,
    card,
    catalog,
    banner,
    animatedBanner,
    homePage,
    quantity,
    visualSettings,
    emails,
    promotionBar,
    searchEngine,
    integrations,
    synchronizations,
    menuSettings,
    technicalBreak,
    notFound,
    notifications,
    units,
    predefinedSearch,
    accountSettings,
    fonts,
    seo,
    cookiesAgreements,
    global,
    mediaLibrary,
    adBanners,
    report,
    translations,
    taxRates,
    orderStatuses,
    languages,
    delivery,
    loginSettings,
    sections,
    shippingForms,
    paymentForms,
    services,
    generalSettings,
    productsDescriptionsImport
};

export default pl;