import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { performAttributeSearch, deleteAttributeById } from '../../redux/methods/attributes';
import useFetch from '../../hooks/useFetch';
import useSearchConfig from '../../hooks/useSearchConfig';

import { generateTranslatedFeaturesAttributesList } from '../../utils/attributes';

import { Container, Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';

import AttributeSearchForm from '../../features/attributes/attributes/AttributeSearchForm';
import AttributeTable from '../../features/attributes/attributes/AttributeTable';
import Paginator from '../../components/Paginator';

import { attributeAffiliationEnum } from '../../enums/attributes';
import { attributesFeaturesSearchConfig } from '../../config/searchConfigs';

import styles from './index.module.scss';

const propTypes = {
    history: PropTypes.object,
    performAttributeSearch: PropTypes.func,
    storeDefaultLanguage: PropTypes.string,
    storeChosenLanguage: PropTypes.string,
    forceRefetch: PropTypes.bool,
    deleteAttributeById: PropTypes.func,
};

const Attributes = ({
    history,
    performAttributeSearch,
    storeChosenLanguage,
    storeDefaultLanguage,
    deleteAttributeById,
    forceRefetch
}) => {
    const intl = useIntl();
    const translations = intl.messages.attributes.view;

    const [ searchConfig, changePage, changeSearchConfig ] = useSearchConfig({ ...attributesFeaturesSearchConfig, attributeAffiliation: attributeAffiliationEnum.attribute });
    const [ attributes, pages ] = useFetch(performAttributeSearch, searchConfig, [searchConfig], forceRefetch);

    const handleAddNewAttribute = () => {
        history.push(`/Attributes/Edit?attrID=-1`);
    };

    const handleRemoveAction = attributeId => {
        deleteAttributeById(attributeId);
        changeSearchConfig({ curentPage: 0 });
    };

    const handleChangePageSize = (size) => {
        changeSearchConfig({ pageSize: size });
    };

    return attributes ? (
        <React.Fragment>
            <Container fluid className={styles.fullScreenOnMobile}>
                <div className="animated fadeIn">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <i className={clsx('fa fa-align-justify')}></i>
                                    <span>{translations.title}</span>
                                </CardHeader>
                                <CardBody className="position-relative">
                                    <main>
                                        <AttributeSearchForm
                                            performAttributeSearch={changeSearchConfig}
                                            searchConfig={searchConfig}
                                        />
                                        <AttributeTable
                                            translatedAttributesList={generateTranslatedFeaturesAttributesList(attributes, storeChosenLanguage || storeDefaultLanguage)}
                                            removeHandler={handleRemoveAction}
                                            history={history}
                                        />
                                        {
                                            pages > 1 &&
                                                <Paginator
                                                    performPageSizeSelect={handleChangePageSize}
                                                    pageSize={searchConfig.pageSize}
                                                    currentPage={searchConfig.curentPage + 1}
                                                    totalPages={pages}
                                                    onPageChange={changePage}
                                                />
                                        }
                                    </main>
                                    <aside className={styles.footer}>
                                        <Button color="primary" onClick={handleAddNewAttribute}>
                                            <i className="fa fa-plus mr-2"/>
                                            <span>{translations.actions.addAttribute}</span>
                                        </Button>
                                    </aside>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    ) : null;
};

Attributes.propTypes = propTypes;

const mapStateToProps = state => ({
    storeChosenLanguage: state.storeLocale.storeChosenLanguage,
    storeDefaultLanguage: state.storeLocale.storeDefaultLanguage,
    forceRefetch: state.refetchers.attributes,
});

const mapDispatch = {
    performAttributeSearch,
    deleteAttributeById
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Attributes));