/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getOwnCode, updateOwnCode } from '../../../redux/methods/seo';

import ContainerWithCard from '../../../components/ContainerWithCard';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTextarea from '../../../components/formFields/FormFieldTextarea';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';

import { Container, Form } from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';

import useFetch from '../../../hooks/useFetch';

import styles from './index.module.scss';

const propTypes = {
    getOwnCode: PropTypes.func,
    updateOwnCode: PropTypes.func
};

const OwnCode = ({ getOwnCode, updateOwnCode }) => {
    const intl = useIntl();
    const translations = intl.messages.seo.ownCode;

    const [ data ] = useFetch(getOwnCode);

    return data ? (
        <ContainerWithCard title={translations.title} iconClass='fa fa-code'>
            <FinalForm
                keepDirtyOnReinitialize
                initialValues={data}
                onSubmit={updateOwnCode}
                render={({ handleSubmit, form }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Container fluid className='p-0'>
                                <div className='mt-3'>
                                    <FormFieldSwitchPill
                                        name='enabled'
                                        label={translations.enabled}
                                    />
                                </div>
                                <div className='mt-3'>
                                    <FormFieldTextarea
                                        className={styles.textareaContainer}
                                        inputClassName={styles.textarea}
                                        label={translations.head}
                                        name='head'
                                    />
                                </div>
                                <div className='mt-3'>
                                    <FormFieldTextarea
                                        className={styles.textareaContainer}
                                        inputClassName={styles.textarea}
                                        label={translations.footer}
                                        name='footer'
                                    />
                                </div>
                                <SectionEditFormButtons formReset={form.reset} />
                            </Container>
                        </Form>
                    );
                }}
            />
        </ContainerWithCard>
    ) : <ContainerWithCard title={translations.title} iconClass='fa fa-code'/>;
};

OwnCode.propTypes = propTypes;

const mapDispatch = {
    getOwnCode,
    updateOwnCode
};

export default connect(null, mapDispatch)(OwnCode);