// this file might be a symlink
// magic values will be replaced during docker container runtime

module.exports = {
    NEXT_SHOP_EXTERNAL_URL: 'https://bto.test1.dev.connectico.pl',
    NEXT_SHOP_EXTERNAL_DOMAIN: 'bto.test1.dev.connectico.pl',
    BACKEND_INTERNAL_URL: 'http://backend:8080',
    BACKEND_EXTERNAL_URL: 'https://panel.bto.test1.dev.connectico.pl/api',
    TINYMCE_API_KEY: 'qil8aezplun7xg2k5si0o8uuzlb9czbkgw54uyapf5hi1dfl'
}
