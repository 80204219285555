const banner = {
    tabSelectorTitle: 'Banner',
    settings: {
        inputs: {
            pauseOnHover: 'Zatrzymaj gdy najedziesz kursorem',
            autoPlay: 'Automatyczne przewijanie',
            enabled: 'Aktywny',
            animationType: 'Typ animacji',
            animationFallback: 'Wybierz typ',
            link: 'Odnośnik tła',
            dateTo: 'Aktywny do',
            dateFrom: 'Aktywny od',
            desktopImage: 'Zdjęcie w widoku desktop',
            tabletImage: 'Zdjęcie w widoku tablet',
            mobileImage: 'Zdjęcie w widoku mobile',
            autoPlayDuration: 'Czas wyświetlania zdjęcia (sek)',
            alternativeText: 'Tekst alternatywny',
            conent: 'Treść',
            buttonLink: 'Odnośnik przycisku',
            buttonContent: 'Treść przycisku',
            buttonColor: 'Kolor tła przycisku',
            saveAlert: 'Zapisz zmiany, aby móc dodać slajd',
            horizontalAlignment: 'Ustawienie przycisku w poziomie',
            verticalAlignment: 'Ustawienie przycisku w pionie',
            buttonFontColor: 'Kolor czcionki przycisku',
            aligments: {
                left: 'Lewo',
                center: 'Środek',
                right: 'Prawo',
                top: 'Góra',
                bottom: 'Dół',
            },
            file: {
                upload: 'PRZEŚLIJ ZDJĘCIE',
                remove: 'USUŃ WSZYSTKIE ZDJĘCIA',
                edit: 'PODMIEŃ ZDJĘCIE',
            },
        },
        button: {
            save: 'ZAPISZ ZMAINY'
        },
        animations: {
            fade: 'Przenikanie',
            standard: 'Slider'
        },
        actions: {
            save: 'ZAPISZ ZMIANY',
            cancel: 'ANULUJ',
            add: 'DODAJ SLAJD'
        },
        editModal: {
            header: 'Edytuj slajd'
        },
        location: 'Lokalizacja',
        columnNames: {
            order: 'Kolejność',
            desktopImage: 'Obraz desktop',
            tabletImage: 'Obraz tablet',
            mobileImage: 'Obraz mobile',
            activeInLanguage: 'Aktywne w językach',
            link: 'Link',
            dates: 'Okres aktywności',
            alternativeText: 'Tekst alternatywny',
            actions: 'Akcje',
            content: 'Treść'
        },
        emptyTable: 'Brak obrazów do wyświetlenia',
        tooltipTranslations: {
            edit: 'Edytuj',
            remove: 'Usuń'
        },
        deleteModalTranslations: {
            header: 'Usuń slajd',
            body: 'Czy chcesz usunąć wybrany slajd?',
            confirm: 'Usuń',
            cancel: 'Wróć'
        }
    },
};

export default banner;