const homePage = {
    mainFormData: {
        dateFrom: '[EN] Aktywna od',
        dateTo: '[EN] Aktywna do',
        activeInLanguages: '[EN] Aktywna w językach',
        name: '[EN] Nazwa sekcji*',
        desktopActive: '[EN] Aktywna na desktopie',
        tabletActive: '[EN] Aktywna na tablecie',
        mobileActive: '[EN] Aktywna na telefonie',
        type: '[EN] Typ sekcji',
        sectionHeight: '[EN] Wysokość sekcji:',
        desktop: '[EN] Dla ekranu komputera (powyżej 1290px)',
        tablet: '[EN] Dla tabletu (powyżej 700px)',
        mobile: '[EN] Dla smartphone',
        numberOfTiles: '[EN] Ilość kafelek w rzędzie:',
        sectionTitle: '[EN] Tytuł sekcji',
        enableTitle: '[EN] Aktywny tytuł',
        pxLabel: 'px',
        marginTop: 'Margines górny',
        marginBottom: 'Margines dolny',
        backgroundColorButton: 'Wybierz kolor',
        backgroundColor: 'Kolor tła',
        fullWidth: 'Sekcja zajmie całą szerokość ekranu'
    },
    promoSections: {
        title: '[EN] Sekcje promocyjne',
        sections: {
            left: '[EN] Lewo',
            right: '[EN] Prawo',
            center: '[EN] Środek',
            bottom: '[EN] Dół',
            top: '[EN] Góra',
            inputs: {
                isActive: '[EN] Aktywność',
                file: {
                    edit: '[EN] PODMIEŃ ZDJĘCIE',
                    remove: '[EN] USUŃ ZDJĘCIE',
                    uplaod: '[EN] PRZEŚLIJ ZDJĘCIE',
                },
                header: '[EN] Nagłówek sekcji',
                content: '[EN] Treść sekcji',
                button: '[EN] Treść buttona',
                link: '[EN] Link tła',
                linkHelper: '[EN] Aby link był dostępny, musisz podać treść przycisku.',
                photoLocation: '[EN] Lokalizacja zdjęcia',
                backgroundColor: '[EN] Kolor tła',
                shadeColor: '[EN] Kolor przesłony',
                backgroundColorButton: '[EN] Wybierz',
                isFullWidth: '[EN] Tło na szerokość okna przeglądarki',
                buttonFontColor: '[EN] Kolor fontu przycisku',
                buttonColor: '[EN] Kolor tła przycisku',
                opacityRange: '[EN] Stopień przyciemnienia',
                manufacturer: '[EN] Producent',
                tag: '[EN] Tag',
                category: '[EN] Kategoria',
                selectCategory: '[EN] Wybierz kategorię',
                changeCategory: '[EN] Zmień kategorię',
                selectNone: '[EN] Brak',
                isFull: '[EN] Sekcja bez margiensów i na całą szerokosć',
            },
            charLimitExceeded: '[EN] Ilość znaków została przekroczona. Zmniejsz ilość znaków aby kontynuować',
            button: '[EN] ZAPISZ ZMIANY',
            one: {
                title: '[EN] Sekcja 1',
            },
            two: {
                title: '[EN] Sekcja 2',
            },
            firstImageLabel: '[EN] Pierwszy kafelek',
            secondImageLabel: 'Drugi kafelek'
        }
    },
    photoWithTextSections: {
        title: '[EN] Sekcje promocyjne',
        sections: {
            left: 'Lewo',
            right: '[EN] Prawo',
            center: 'Środek',
            bottom: 'Dół',
            top: 'Góra',
            inputs: {
                file: {
                    edit: '[EN] PODMIEŃ ZDJĘCIE',
                    remove: '[EN] USUŃ ZDJĘCIE',
                    uplaod: '[EN] PRZEŚLIJ ZDJĘCIE',
                },
                linkBackground: 'Link tło',
                linkButton: 'Link przycisk',
                buttonText: '[EN] Tekst na przycisku',
                horizontalAlignment: '[EN] Pozycja przycisku w poziomie',
                verticalAlignment: '[EN] Pozycja przycisku w pionie',
                buttonColorText: 'Kolor czcionki na przycisku',
                buttonColorBackground: 'Kolor tła przycisku',
                content: '[EN] Treść',

            },
            button: 'ZAPISZ ZMIANY',
        }
    },
    videoSection: {
        title: 'Sekcja video',
        sections: {
            left: 'Lewo',
            right: 'Prawo',
            center: 'Środek',
            bottom: 'Dół',
            top: 'Góra',
            fullScreen: 'Cały ekran',
            inputs: {
                file: {
                    edit: 'PODMIEŃ VIDEO',
                    remove: 'USUŃ VIDEO',
                    upload: 'PRZEŚLIJ VIDEO',
                },
                backgroundColor: 'Kolor tła',
                backgroundColorButton: 'Wybierz',
                linkBackground: 'Link tło',
                linkButton: 'Link przycisk',
                buttonText: 'Tekst na przycisku',
                horizontalAlignment: 'Pozycja przycisku w poziomie',
                verticalAlignment: 'Pozycja przycisku w pionie',
                buttonColorText: 'Kolor czcionki na przycisku',
                buttonColorBackground: 'Kolor tła przycisku',
                content: 'Treść',
                autoplay: 'Autoodtwarzanie',
                loop: 'Zapętlenie',
                mute: 'Wyciszenie',
                videoPosition: 'Położenie video',

            },
            button: 'ZAPISZ ZMIANY',
        }
    },
    metatagsSectionTitle: 'Metatagi Strony Głównej',
    general: {
        title: 'Ustawienia ogólne Strony Głównej',
        labels: {
            backgroundColor: 'Kolor tła'
        },
        buttons: {
            select: 'Wybierz'
        }
    },
    sections: {
        actions: {
            add: 'DODAJ NOWĄ SEKCJĘ',
        },
        sections: {
            sliderWithButton: 'Banner',
            articlesWithBookmarks: 'Lista artykułów',
            articles: 'Zakładki artykułów',
            photoWithText: 'Zdjęcie z tekstem',
            tiny: 'Treść własna',
            promoted: 'Sekcja promowana',
            doubleImage: 'Podwójna sekcja promowana',
            banner: 'Banner',
            manufacturer: 'Karuzela producentów',
            video: 'Sekcja Video',
            animatedBanner: 'Animowany banner',
            contactForm: 'Formularz kontaktowy',
            map: 'Mapa'
        },
        additionModal: {
            confirm: 'DODAJ',
            cancel: 'ANULUJ',
            header: 'Wybór typu sekcji',
            body: 'Podgląd sekcji'
        },
        titles: {
            desktop:'Sekcje desktopowe',
            tablet:'Sekcje tabletowe',
            mobile:'Sekcje mobilne'
        }
    },
    table: {
        headers: {
            order: 'Kolejność',
            name: '[EN] Nazwa',
            type: '[EN] Typ sekcji',
            desktopActive: '[EN] Aktywna na desktopie',
            tabletActive: '[EN] Aktywna na tablecie',
            mobileActive: '[EN] Aktywna na telefonie',
            activeFrom: '[EN] Aktwyna od',
            activeTo: '[EN] Aktywna do',
            activeInLanguages: '[EN] Aktywna w językach',
            actions: '[EN] Akcje'
        },
        translations: {
            tooltips: {
                edit: 'Edytuj',
                remove: '[EN] Usuń',
                copy: '[EN] Kopiuj'
            },
            deleteModal: {
                header: '[EN] Usuwanie predefiniowanego wyszukiwania',
                body: '[EN] Czy na pewno chcesz usunąć predefiniowane wyszukiwanie?',
                confirm: '[EN] USUŃ',
                cancel: '[EN] ANULUJ'
            },
            emptyTable: '[EN] Brak predefiniowanych wyszukań'
        }
    },
    edit: {
        title: '[EN] Edycja sekcji',
        productAssigner: 'Przypisywanie produktów',
        categoryAssigner: 'Przypisywanie kategorii',
        bannerImages: 'Lista slajdów',
        animatedBannerImages: 'Lista slajdów',
    },
    manufacturersSection: {
        main: {
            button: '[EN] ZAPISZ ZMIANY',
            title: '[EN] Ustawienia ogólne',
            amountToShow: '[EN] Liczba producentów na jednej stronie',
            backgroundColor: 'Kolor tła:',
            backgroundColorButton: 'Wybierz kolor'
        },
        manufacturers: {
            title: '[EN] Wybór producentów',
            tooltips: {
                edit: '[EN] Edytuj producenta',
                remove: '[EN] Odłącz producenta',
            },
            deleteModal: {
                header: '[EN] Odłączanie producenta',
                body: '[EN] Czy chcesz odłączyć producenta?',
                confirm: '[EN] ODŁĄCZ',
                cancel: '[EN] ANULUJ',
            },
            emptyTable: '[EN] Brak producentów',
            headers: {
                logo: '[EN] Logo',
                name: '[EN] Nazwa',
                order: '[EN] Kolejność',
                active: '[EN] Aktywność',
                actions: '[EN] Akcje',
            },
            nameFiller: '[EN] [BRAK NAZWY]',
            buttons: {
                add: '[EN] PRZYPISZ PRODUCENTA',
                remove: '[EN] ODŁĄCZ ZAZNACZONYCH',
            },
            modal: {
                header: '[EN] Przypisywanie producentów',
                cancel: '[EN] ANULUJ',
                confirm: '[EN] PRZYPISZ',
            }
        }
    },
    contactFormSection: {
        isRequiredLabel: 'Wymagane',
        customFieldNameLabel: 'Nazwa pola własnego',
        fields: {
            nameSurname: 'Imię i Nazwisko',
            firstName: 'Imię',
            surname: 'Nazwisko',
            companyName: 'Nazwa firmy',
            postalCode: 'Kod pocztowy',
            country: 'Kraj',
            nip: 'NIP',
            phoneNumber: 'Telefon',
            email: 'E-mail',
            content: 'Treść zapytania',
            customField: 'Pole własne'
        },
        inputs: {
            contactFormFieldsLabel: 'Lista widocznych pól formularza kontaktowego',
            emailControlLabel: 'Lista adresów e-mail na które zostanie wysłana wiadomość',
            emailLabel: 'Adres e-mail'
        },
        buttons: {
            removeEmail: 'Usuń',
            addEmail: 'Dodaj adres e-mail'
        }
    },
    mapSection: {
        inputs: {
            mapSize: 'Rozmiar mapy',
            chooseMapSize: 'Wybierz rozmiar mapy'
        },
        mapSizes: {
            Small: 'XS - 300px',
            Medium: 'S - 500px',
            Large: 'M - 700px',
            VeryLarge: 'L - 1024px',
            Huge: 'XL - 1200px',
            FullScreen: 'XXL - pełna szerokość ekranu'
        },
        mapGuides: '- Kliknij dwa razy w wybrane miejsce aby ustawić znacznik',
    }
};

export default homePage;