import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useFetch from '../../../hooks/useFetch';
import { getMetatags, updateMetatags } from '../../../redux/methods/extrasSettings';

import { Form as FinalForm } from 'react-final-form';
import { Container, Form, Row, Alert } from 'reactstrap';
import SectionEditFormButtons from '../../../components/SectionEditFormButtons';
import FormFieldTranslations from '../../../components/formFields/FormFieldTranslations';
import ContainerWithCard from '../../../components/ContainerWithCard';
import FormFieldTextarea from '../../../components/formFields/FormFieldTextarea';
import FormFieldSwitchPill from '../../../components/formFields/FormFieldSwitchPill';

import { withFormUtils } from '../../../hoc/withFormUtils';
import { formUtilsHocPropTypes } from '../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    utils: formUtilsHocPropTypes.isRequired,
    id: PropTypes.number,
    getMetatags: PropTypes.func,
    updateMetatags: PropTypes.func
};

const MetatagsSection = ({ utils, getMetatags, updateMetatags, forcedTag, forcedPageId }) => {
    const history = useHistory();
    const intl = useIntl();
    const translations = intl.messages.global;

    const pathChunks = history.location.pathname.split('/');

    const tag = pathChunks.length > 1 && pathChunks[pathChunks.length - 2] === 'metaTags'
        ? pathChunks[pathChunks.length - 1]
        : forcedTag;

    const config = {
        translations: ['description', 'keywords', 'robots', 'title']
    };

    const tagNames = {
        description: 'Description:',
        keywords: 'Keywords:',
        robots: 'Robots:',
        title: 'Title:'
    };

    const [ data ] = useFetch(getMetatags, { tag: tag, pageid: forcedPageId});

    const handleOnSubmit = formData => {
        updateMetatags({ tag: tag, pageid: forcedPageId, formData: utils.generateFormToSubmitValues(formData, config) });
    };

    const isScriptValid = script => {
        return script ? script.slice(0, 7) === '<script' && script.slice(-9) === '</script>' : true;
    };

    const isValid = values => isScriptValid(values.head) && isScriptValid(values.footer);

    return data ? (
            <ContainerWithCard title={translations.metaTags} iconClass='fa fa-code'>
                <FinalForm
                    /** This weird key is here to ensure that the Form is remounted and reinitialised when needed */
                    key={`MetatagsSectionSectionForm-${utils.currentLanguage()}`}
                    initialValues={utils.generateFormInitialValues(data, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container className='p-3 px-0' fluid>
                                    {
                                        Object.keys(tagNames)
                                            .map(tagName => {
                                                return (
                                                    <Row key={tagName} className="my-3">
                                                        <FormFieldTranslations
                                                            form={form}
                                                            propertyName={tagName}
                                                            currentIso={utils.currentLanguage()}
                                                            modalHeader={tagNames[tagName]}
                                                            label={tagNames[tagName]}
                                                        />
                                                    </Row>
                                                );
                                            })
                                    }
                                    <Row className='mt-3'>
                                        <Alert color="info" isOpen={!isValid(values)}>
                                            {translations.scriptFormatAlert}
                                        </Alert>
                                    </Row>
                                    <Row className='mt-3'>
                                        <FormFieldSwitchPill
                                            name='codeEnabled'
                                            label={translations.codeEnabled}
                                        />
                                    </Row>
                                    <Row className='mt-3'>
                                        <FormFieldTextarea
                                            className={styles.textareaContainer}
                                            inputClassName={styles.textarea}
                                            label={translations.head}
                                            name='head'
                                        />
                                    </Row>
                                    <Row className='mt-3'>
                                        <FormFieldTextarea
                                            className={styles.textareaContainer}
                                            inputClassName={styles.textarea}
                                            label={translations.footer}
                                            name='footer'
                                        />
                                    </Row>
                                </Container>
                                <SectionEditFormButtons formReset={form.reset} isSaveButtonDisabled={!isValid(values)} />
                            </Form>
                        );
                    }}
                />
            </ContainerWithCard>
    ) : null;
};

MetatagsSection.propTypes = propTypes;

const mapDispatch = {
    getMetatags,
    updateMetatags
};

export default withFormUtils(connect(null, mapDispatch)(MetatagsSection));