import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { selectStoreCurrentLanguage } from '../../../../redux/selectors/storeLocale';
import { addUpdatePageSection, uploadSectionPhotoWithTextImage } from '../../../../redux/methods/homePage';
import { getButtonsList } from '../../../../redux/methods/visualSettings';
import getConfig from '../../../../getConfig';
import { targetTranslatedContent } from '../../../../utils/components';

import { Form as FinalForm } from 'react-final-form';
import { Form, Row } from 'reactstrap';

import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import FormFieldTranslationsQuill from '../../../../components/formFields/FormFieldTranslationsQuill';
import HomePageSectionMainData from '../HomePageSectionMainData';
import PreviewModalController from '../../../../components/PreviewModalController';
import ButtonPreview from '../../../../components/ButtonPreview';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import styles from './index.module.scss';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import FormFieldWithCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldDefiningButton from '../../../../components/formFields/FormFieldDefiningButton';
import MediaSelectionModalWithButtons from '../../../mediaLibrary/MediaSelectionModalWithButtons';

import Tabulator from '../../../../components/Tabulator';

const propTypes = {
    currentLanguage: PropTypes.string,
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object,
    uploadSectionPhotoWithTextImage: PropTypes.func,
};

const VideoSection= ({
    currentLanguage,
    utils,
    section,
    addUpdatePageSection,
    buttons,
    getButtonsList,
    sectionLocation
}) => {
    const history = useHistory();
    const intl = useIntl();
    const translations = intl.messages.homePage.videoSection.sections;
    const translationsHomePage = intl.messages.homePage;

    const [ isSelectionModalOpen, setIsSelectionModalOpen ] = useState(false);
    const [ selectedVideo, setSelectedVideo ] = useState([]);

    useEffect(() => {
        if(!buttons || buttons.length === 0) {
            getButtonsList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(section && section.videoSourceId !== 0) {
            setSelectedVideo([
                {
                    id: section?.videoSourceId,
                    thumbnailSource: section?.videoSource,
                }
            ]);
        }
    }, [ section ]);

    const apiUrl = getConfig().BACKEND_EXTERNAL_URL;
    const config = { translations: ['content', 'button', 'title'], date: ['activeFrom', 'activeTo'] };

    const handleOnSubmit = async formData => {
        const objectToSend = {
            ...utils.generateFormToSubmitValues(formData, config),
            videoSourceId: selectedVideo[0]?.id,
        };
        const response = await addUpdatePageSection(objectToSend);

        if(typeof response === 'number') {
            history.push(`edit?sectionID=${response}&sectionType=${section.type}&location=${sectionLocation}`);

            return;
        }

        return response;
    };

    const horizontalAlignment = [
        {
            id: 'Left',
            label: translations.left
        },
        {
            id: 'Center',
            label: translations.center
        },
        {
            id: 'Right',
            label: translations.right
        },
    ];

    const verticalAlignment = [
        {
            id: 'Top',
            label: translations.top
        },
        {
            id: 'Center',
            label: translations.center
        },
        {
            id: 'Bottom',
            label: translations.bottom
        },
    ];

    const videoPosition = [
        {
            id: 'FullScreen',
            label: translations.fullScreen,
        },
        {
            id: 'Left',
            label: translations.left,
        },
        {
            id: 'Right',
            label: translations.right,
        },
    ];

    const videoPositionStyles = {
        Left: styles.containerLeft,
        Right: styles.containerRight,
    };

    return section ? (
        <div className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(section, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {
                        const selectedButton = buttons.find(button => button.id === values.buttonId);
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translationsHomePage.tabs.basicData,
                                            id: 'searchTerms-table',
                                            component: <HomePageSectionMainData
                                                isSectionHeightController={true}
                                                isNumberOfTilesController={false}
                                                form={form} />
                                        },
                                        {
                                            label: translationsHomePage.tabs.settings,
                                            id: 'lastPhrases-table',
                                            component:
                                                <React.Fragment>
                                                    <Row className='mt-2 px-3'>
                                                        <FormFieldTextOrNumber
                                                            label={translations.inputs.linkBackground}
                                                            name='link'
                                                            className='w-100'
                                                        />
                                                    </Row>
                                                    <Row className='mt-3 px-3'>
                                                        <FormFieldWithDropdown
                                                            currentSelectionLabel={horizontalAlignment.find(opt => opt.id === values.horizontalAlignment)?.label}
                                                            label={translations.inputs.horizontalAlignment}
                                                            name='horizontalAlignment'
                                                            options={horizontalAlignment}
                                                        />
                                                    </Row>
                                                    <Row className='my-3 px-3'>
                                                        <FormFieldWithDropdown
                                                            currentSelectionLabel={verticalAlignment.find(opt => opt.id === values.verticalAlignment)?.label}
                                                            label={translations.inputs.verticalAlignment}
                                                            name='verticalAlignment'
                                                            options={verticalAlignment}
                                                        />
                                                    </Row>
                                                    <Row className='my-3 px-3'>
                                                        <FormFieldWithDropdown
                                                            currentSelectionLabel={videoPosition.find(opt => opt.id === values.verticalAlignment)?.label}
                                                            label={translations.inputs.videoPosition}
                                                            name='videoPosition'
                                                            options={videoPosition}
                                                        />
                                                    </Row>
                                                    <FormFieldDefiningButton form={form} />
                                                    <Row className='mt-2 px-3'>
                                                        <FormFieldTranslationsQuill
                                                            form={form}
                                                            utils={utils}
                                                            propertyName="content"
                                                            header={translations.inputs.content}
                                                        />
                                                    </Row>
                                                    <Row className='mt-2 px-3'>
                                                        <FormFieldWithCheckbox
                                                            name='autoPlay'
                                                            label={translations.inputs.autoplay}
                                                        />
                                                    </Row>
                                                    <PreviewModalController
                                                        content={(
                                                            <div
                                                                className={clsx(styles.container, videoPositionStyles[values.videoPosition])}
                                                                style={{
                                                                    height: `${values.desktopSettings?.height}px`,
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <div className={clsx(styles.videoContainer, values.videoPosition === 'FullScreen' && styles.videoContainerFull)}>
                                                                    <video
                                                                        autoPlay={values.autoPlay}
                                                                        muted={true}
                                                                        loop={'loop'}
                                                                        controls={false}
                                                                        height={'100%'}
                                                                        width={'100%'}
                                                                    >
                                                                        <source src={`${apiUrl}${values.videoSource}`} type='video/mp4' />
                                                                    </video>
                                                                </div>
                                                                <div className={clsx(styles.content, values.videoPosition === 'FullScreen' && styles.contentFullScreen)}>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{ __html: values[targetTranslatedContent('content', currentLanguage)] }}
                                                                    />
                                                                    {values[targetTranslatedContent('button', currentLanguage)] && selectedButton &&
                                                                        <div
                                                                            className={styles.button}
                                                                            style={{ textAlign: values.horizontalAlignment && values.horizontalAlignment.toLowerCase()}}
                                                                        >
                                                                            <ButtonPreview
                                                                                previewTranslation={values[targetTranslatedContent('button', currentLanguage)]}
                                                                                button={selectedButton && selectedButton.style}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                    <MediaSelectionModalWithButtons
                                                        checkedItems={selectedVideo}
                                                        setCheckedItems={setSelectedVideo}
                                                        isOpen={isSelectionModalOpen}
                                                        setIsOpen={setIsSelectionModalOpen}
                                                        buttons={translations.inputs.file}
                                                    />
                                                </React.Fragment>
                                        },
                                    ]}
                                />
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.button,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        }
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    ) : null;
};

VideoSection.propTypes = propTypes;

const mapStateToProps = state => ({
    currentLanguage: selectStoreCurrentLanguage(state),
    buttons: state.visualSettings.buttons,
});

const mapDispatch = {
    addUpdatePageSection,
    uploadSectionPhotoWithTextImage,
    getButtonsList
};

export default withFormUtils(connect(mapStateToProps, mapDispatch)(VideoSection));