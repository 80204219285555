import { cAPI } from '../../../utils/cAPI';
import { getData, addEditData, removeData } from '../generic';
import { appInit } from '../appInit';

export const getUsers = searchConfig => dispatch => getData(dispatch, cAPI.APGetUsers, searchConfig, 'users');
export const updateUserData = formData => async dispatch => {
    await addEditData(dispatch, cAPI.APUpdateUserData, formData);
    dispatch(appInit());
};

export const getUserData = id => dispatch => getData(dispatch, cAPI.getUserDataForAdmin, id, 'user');

export const changeUserStatus = formData => dispatch => addEditData(dispatch, cAPI.APChangeUserStatus, formData, ['users', 'user']);
export const changeUserEmail = formData => dispatch => addEditData(dispatch, cAPI.APChangeUserEmail, formData);
export const removeUser = id => dispatch => removeData(dispatch, cAPI.APRemoveUser, id, 'users');
