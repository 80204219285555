import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCountries, updateCountryNames, updateCountryActive } from '../../redux/methods/countries';

import { generateFormInputs, generateFilteredAndTranslatedCountriesList } from '../../utils/countries';

import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import CountrySearchForm from '../../features/countries/CountrySearchForm';
import CountriesTable from '../../components/tables/CountriesTable';
import EditFormModalWithTextInputFlags from '../../components/EditFormModalWithTextInputFlags';

import styles from './index.module.scss';
import useFetch from '../../hooks/useFetch';

const propTypes = {
    getCountries: PropTypes.func,
    updateCountryNames: PropTypes.func,
    updateCountryActive: PropTypes.func,
    storeChosenLanguage: PropTypes.string,
    storeDefaultLanguage: PropTypes.string,
    forceRefetch: PropTypes.bool,
};

const Countries = ({ getCountries, updateCountryNames, updateCountryActive, storeChosenLanguage, storeDefaultLanguage, forceRefetch }) => {
    const intl = useIntl();
    const translations = intl.messages.countries.view;

    const [ filters, setFilters ] = useState({ country: '', iso: '' });
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ countryToEdit, setCountryToEdit ] = useState(null);
    const [ filteredAndTranslatedCountries, setFilteredAndTranslatedCountries] = useState([]);

    const [ countries ] = useFetch(getCountries, null, [], forceRefetch);

    useEffect(() => {
        setIsModalOpen(false);
        setCountryToEdit(null);
    }, [countries]);

    const handleEditClick = country => {
        setCountryToEdit(country);
        setIsModalOpen(true);
    };

    const handleSubmit = data => {
        const availableIsoList = countryToEdit.translations.map(({ iso }) => iso);
        updateCountryNames(countryToEdit.id, data, availableIsoList);
    };

    const handleUpdateActiveAction = data => {
        updateCountryActive(data);
    };

    const handleSearchSubmit = formData => {
        setFilters({ country: formData.country, iso: formData.iso });
        setFilteredAndTranslatedCountries(countries && generateFilteredAndTranslatedCountriesList(
            countries, formData.country, formData.iso, storeChosenLanguage, storeDefaultLanguage
        ));
    };

    useEffect(() => {
        setFilteredAndTranslatedCountries(countries && generateFilteredAndTranslatedCountriesList(
            countries, filters.country, filters.iso, storeChosenLanguage, storeDefaultLanguage
        ));
    }, [countries]);

    return filteredAndTranslatedCountries ? (
        <Container fluid className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <i className={clsx('fa fa-align-justify')}></i>
                                <span>{translations.title}</span>
                            </CardHeader>
                            <CardBody>
                                <main>
                                    <CountrySearchForm handleSubmit={handleSearchSubmit} filters={filters}/>
                                    <CountriesTable
                                        countries={filteredAndTranslatedCountries}
                                        headerLabels={translations.headerLabels}
                                        currencyIsActiveText={translations.currencyIsActiveText}
                                        translations={translations}
                                        editClickAction={handleEditClick}
                                        updateActiveAction={handleUpdateActiveAction}
                                        nameFiller={translations.nameFiller}
                                    />
                                </main>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {countryToEdit &&
                <EditFormModalWithTextInputFlags
                    header={`${translations.modalHeader} ${countryToEdit.name}`}
                    isEditModalOpen={isModalOpen}
                    setIsEditModalOpen={setIsModalOpen}
                    formInputs={generateFormInputs(countryToEdit, translations.labelText)}
                    onSubmit={handleSubmit}
                />
            }
        </Container>
    ) : null;
};

Countries.propTypes = propTypes;

const mapStateToProps = state => ({
    storeChosenLanguage: state.storeLocale.storeChosenLanguage,
    storeDefaultLanguage: state.storeLocale.storeDefaultLanguage,
    forceRefetch: state.refetchers.countries,
});

const mapDispatch = {
    getCountries,
    updateCountryNames,
    updateCountryActive
};

export default connect(mapStateToProps, mapDispatch)(Countries);