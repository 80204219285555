const dashboard = {
    general: {
        title: '[EN] Panel handlu',
        chartSection: {
            labels: {
                numberOfVisits: '[EN] Ilość odwiedzin',
                searchByUserTime: '[EN] Czas użytkownika' ,
                searchByAdminTime: '[EN] Czas administratora',
            },
            dateRangeTypes: {
                day: '[EN] Dzień',
                month: '[EN] Miesiąc',
                year: '[EN] Rok',
                custom: '[EN] Zakres'
            },
            incorrectDatesAlert: '[EN] Ustawione daty są nieprawidłowe',
            emptyData: '[EN] Brak danych dla wybranego zakresu.'
        },
        statsSection: {
            labels: {
                searchTerms: '[EN] Wyszukiwane frazy',
                productSearch: '[EN] Najczęściej odwiedzane',
                lastPhrases: '[EN] Ostatnio wyszukiwane',
            },
            headers: {
                phrase: '[EN] Fraza',
                date: '[EN] Data',
                numberOfSearches: '[EN] Ilość wyszukiwań',
                name: '[EN] Nazwa',
                visitNumber: '[EN] Ilość odwiedzin',
                catalogueIndex: '[EN] Indeks katalogowy',
                dateFrom: '[EN] Data od',
                dateTo: '[EN] Data do',
                minSearches: '[EN] Minimalna ilość wyszukań'
            },
            buttons: {
                reset: '[EN] RESETUJ',
                submit: '[EN] SZUKAJ'
            },
        }
    },
    clients: {
        title: '[EN] Panel handlu / klienci',
        table: {
            headers: {
                login: '[EN] Login',
                name: '[EN] Imię',
                surname: '[EN] Nazwisko',
                contractorInErp: '[EN] Kontrahent w Erp',
                accountType: '[EN] Typ konta',
                isActive: '[EN] Aktywny',
                registerDate: '[EN] Data rejestracji',
                actions: '[EN] Akcje',
            },
            emptyTable: '[EN] Brak klientów',
            tooltips: {
                edit: '[EN] Edycja',
                remove: '[EN] Usuwanie',
            },
            deleteModal: {
                header: '[EN] Usuwanie użytkownika',
                body: '[EN] Czy na pewno chce usunąć użytkownika?',
                confirm: '[EN] USUŃ',
                cancel: '[EN] ANULUJ',
            }
        },
        searchForm: {
            hasAccount: '[EN] Posiada konto',
            login: '[EN] Login',
            name: '[EN] Imię',
            surname: '[EN] Nazwisko',
            contractorInErp: '[EN] Kontrahent w Erp',
            email: '[EN] Email',
            isActive: '[EN] Aktywny',
            action: '[EN] SZUKAJ',
            reset: '[EN] RESETUJ',
            true: '[EN] Tak',
            false: '[EN] Nie',
            null: '[EN] Dowolnie',
        },
        edit: {
            title: '[EN] Dane użytkownika',
            contractor: '[EN] Dane kontrahenta',
            nip: '[EN] Nip',
            companyName: '[EN] Nazwa firmy',
            firstName: '[EN] Imię',
            lastName: '[EN] Nazwisko',
            role: '[EN] Rola',
            contact: '[EN] Dane kontaktowe',
            firstNameAndLastName: '[EN] Imię i nazwisko',
            phoneNumber: '[EN] Telefon',
            address: '[EN] Adres',
            place: '[EN] Miejsce dostawy',
            zipCode: '[EN] Kod pocztowy',
            city: '[EN] Miasto',
            recipient: '[EN] Odbiorca',
            active: '[EN] Aktywny',
            generatePwd: '[EN] Wygeneruj nowe hasło',
            emailPlaceholder: '[EN] Nowy email',
            email: '[EN] Zmień email',
            confirmation: '[EN] Wysłano maila!',
        },
    },
    orders: {
        title: '[EN] Panel handlu / zamówienia'
    },
    parcels: {
        title: '[EN] Panel handlu / paczki'
    },
    stats: {
        title: '[EN] Panel handlu / statystyki',
        chartSection: {
            labels: {
                numberOfVisits: '[EN] Ilość odwiedzin',
                searchByUserTime: '[EN] Czas użytkownika' ,
                searchByAdminTime: '[EN] Czas administratora',
            },
            dateRangeTypes: {
                day: '[EN] Dzień',
                month: '[EN] Miesiąc',
                year: '[EN] Rok',
                custom: '[EN] Zakres'
            },
            incorrectDatesAlert: '[EN] Ustawione daty są nieprawidłowe',
            emptyData: '[EN] Brak danych dla wybranego zakresu.'
        },
        statsSection: {
            labels: {
                searchTerms: '[EN] Wyszukiwane frazy',
                productSearch: '[EN] Najczęściej odwiedzane',
                lastPhrases: '[EN] Ostatnio wyszukiwane',
            },
            headers: {
                phrase: '[EN] Fraza',
                date: '[EN] Data',
                numberOfSearches: '[EN] Ilość wyszukiwań',
                name: '[EN] Nazwa',
                visitNumber: '[EN] Ilość odwiedzin',
                catalogueIndex: '[EN] Indeks katalogowy',
                dateFrom: '[EN] Data od',
                dateTo: '[EN] Data do',
                minSearches: '[EN] Minimalna ilość wyszukań'
            },
            buttons: {
                reset: '[EN] RESETUJ',
                submit: '[EN] SZUKAJ'
            },
        }
    },
};

export default dashboard;