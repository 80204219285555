import React from 'react';
import PropTypes from 'prop-types';

import { generateNonIdsOptionsForDropdown } from '../../../utils/components';

import { Label, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';
import Dropdown from '../../../components/Dropdown';

import ArticlePresentationCardTable from '../../../components/tables/ArticlePresentationCardTable';

const propTypes = {
    translations: PropTypes.object
};

const ProductListDeviceSettings = ({
    prefix,
    translations,
    translationsAdditionalFields,
    manufacturerFields,

    selectedAdditionalFieldIds,
    additionalFieldSelectionLabel,
    handleAdditionalFieldsSelection,

    selectedAdditionalField2Ids,
    additionalField2SelectionLabel,
    handleAdditionalFields2Selection,

    selectedManufacturerFieldIds,
    manufacturerSelectionLabel,
    handleManufacturerFieldSelection,

    count,
    generatedItemCountOptions,
    allTagAttributesOptions,
    allTagAttributes2Options,
    additionalFieldsToEdit,
    setAdditionalFieldsToEdit,
    values
}) => {
    return additionalFieldsToEdit && translationsAdditionalFields ? (
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        {translations.labels.generalSettings}
                    </CardHeader>
                    <CardBody>
                        <FormFieldWithDropdown
                            name={`${prefix}.itemCount`}
                            options={generatedItemCountOptions}
                            label={translations.inputs.productsQuantity}
                            currentSelectionLabel={count.label}
                        />
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        {translations.labels.optionalFields}
                    </CardHeader>
                    <CardBody>
                        <div className='mt-3'>
                            <Label>
                                {translations.inputs.imageTags}
                            </Label>
                            <Dropdown
                                name={`${prefix}.additionalField`}
                                withCheckboxes={true}
                                options={allTagAttributesOptions}
                                performSelection={handleAdditionalFieldsSelection}
                                checkedIds={selectedAdditionalFieldIds}
                                currentSelectionLabel={additionalFieldSelectionLabel}
                            />
                        </div>
                        <div className='mt-3'>
                            <Label>
                                {translations.inputs.imageTags2}
                            </Label>
                            <Dropdown
                                name={`${prefix}.additionalField2`}
                                withCheckboxes={true}
                                options={allTagAttributes2Options}
                                performSelection={handleAdditionalFields2Selection}
                                checkedIds={selectedAdditionalField2Ids}
                                currentSelectionLabel={additionalField2SelectionLabel}
                            />
                        </div>
                        <div className='mt-3'>
                            <Label>{translations.inputs.manufacturerField}</Label>
                            <Dropdown
                                name={`${prefix}.manufacturerField`}
                                withCheckboxes={true}
                                options={generateNonIdsOptionsForDropdown(manufacturerFields, translations.manufacturerFields)}
                                performSelection={handleManufacturerFieldSelection}
                                checkedIds={selectedManufacturerFieldIds}
                                currentSelectionLabel={manufacturerSelectionLabel}
                            />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showReviews`}
                                label={translations.inputs.showReviews} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showQuantity`}
                                label={translations.inputs.showQuantity} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showAddToFavourites`}
                                label={translations.inputs.showAddToFavourites} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showAddToComparision`}
                                label={translations.inputs.showAddToComparision} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showRelated`}
                                label={translations.inputs.showRelated} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showAddToCart`}
                                label={translations.inputs.showAddToCart} />
                        </div>
                        {values[prefix].showAddToCart &&
                            <div className='mt-3'>
                                <FormFieldWithCheckbox
                                    name={`${prefix}.showQuantitySelection`}
                                    label={translations.inputs.showQuantitySelection} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card>
                    <CardHeader>
                        {translations.labels.additionalFields}
                    </CardHeader>
                    <CardBody>
                        <ArticlePresentationCardTable
                            type='list'
                            device={prefix}
                            parameters={additionalFieldsToEdit}
                            setParameters={setAdditionalFieldsToEdit}
                            headerLabels={translationsAdditionalFields.headerLabels}
                            translations={translationsAdditionalFields.table.labels}
                            setIsEdited={() => {}}
                            checkLimit={8}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    ) : null;
};

ProductListDeviceSettings.propTypes = propTypes;

export default ProductListDeviceSettings;