const general = {
    settings: {
        tabs: {
            articles: {
                title: "Artykuły",
                button: {
                    save: 'ZAPISZ'
                },
                inputs: {
                    showOnlyAvailable: 'Pokazuj wyłącznie artykuły dostępne',
                    showAvailabilityFilter: 'Pokazuj filtr dostępności',
                    displayNetPrice: 'Pokazuj cenę netto',
                    canOrderUnavailable: 'Pozwalaj na zamawianie artykułów niedostępnych',
                    canOrderOverStock: 'Pozwalaj na zamawianie artykułów ponad stan',
                    displayPrice: 'Wyświetlaj cenę przed promocją.',
                    displayPercentageDiscount: 'Wyświetlaj procentową zniżkę.',
                    displayDate: 'Wyświetlaj czas trwania promocji.',
                    infiniteScroll: 'Ładuj nowe produkty podczas scrollowania.',
                    displayCurrency: 'Nie wyświetlaj walut.',
                    defaultView: 'Domyślny widok',
                    isListCondensed: 'Układ skondensowany',
                    views: 'Aktywne widoki',
                    list: 'Lista',
                    tile: 'Kafelki',
                    typeOfUnitPresentation: 'Sposób prezentowania jednostki',
                    typeOfUnitPresentationOptions: {
                        name: 'Nazwa',
                        shortcut: 'Skrót',
                    },
                    emptyLabel: 'Brak',
                    defaultCurrencyId: 'Domyślna waluta',
                    defaultLanguageId: 'Domyślny język'
                }
            },
            categories: {
                title: 'Kategorie',
                settingsTitle: 'Ustawienia kategorii',
                searchAfterCategoryTreeClick: 'Wyszukuj produkty podczas przeglądania drzewa kategorii',
                showProductsFromSubcategories: 'Pokazuj produkty zawarte w kategoriach podrzędnych',
                showCategoryTreeOnSearchPage: 'Pokazuj drzewo kategorii na liście produktów ',
                showOnlyOneCategoryExpanded: 'Pokazuj rozwiniętą tylko jedną gałąź w drzewie kategorii',
                showOnlySubTreeOfSelectedCategory: 'Pokazuj wyłącznie drzewo podrzędne wybranej kategorii',
                showSubCategoriesTilesAboweSearchResult: 'Pokazuj kafelki podkategorii nad wynikami wyszukiwania',
                showPictogramsInCategoryMenu: 'Pokazuj piktogramy w menu kategorii',
                inheritPictogramsInCategoryMenu: 'Dziedzicz piktogramy w menu kategorii',
                halfSize: 'Zdjęcie',
                pictogram: 'Piktogram',
                presentationType: 'Typ wyświetlanego obrazka na kafelku',
                numberOfColumnsInCategoryMenu: 'Ilość kolumn w menu kategorii',
                showOnlySubcategoriesAboveSearchResult: 'Pokazuj tylko kafelki podkategorii nad wynikami wyszukiwania',
                categoryMenuAnimation: 'Pokazuj animację menu kategorii'
            }
        }
    }
};

export default general;