import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withFormUtils } from '../../../../hoc/withFormUtils';
import { addUpdatePageSection } from '../../../../redux/methods/homePage';

import { defaultContactFormOptions } from '../../../../config';

import { Form as FinalForm } from 'react-final-form';
import { Card, CardHeader, CardBody, Row, Col, Form } from 'reactstrap';
import ContactFormCreator from '../../../../components/ContactFormCreator';
import EmailListController from '../../../../components/EmailListController';
import SectionEditFormButtons from '../../../../components/SectionEditFormButtons';
import HomePageSectionMainData from '../HomePageSectionMainData';
import FormFieldColorPicker from '../../../../components/formFields/FormFieldColorPicker';

import { formUtilsHocPropTypes } from '../../../../propTypes';

import Tabulator from '../../../../components/Tabulator';

const propTypes = {
    utils: formUtilsHocPropTypes,
    section: PropTypes.object,
    addUpdatePageSection: PropTypes.func
};

const ContactFormSection = ({ utils, section, addUpdatePageSection, sectionLocation }) => {
    const intl = useIntl();
    const translations = intl.messages.homePage.contactFormSection;
    const translationsHomePage = intl.messages.homePage;

    const defaultContactFormOptionsTypes = {
        'firstName': 'text',
        'surname': 'text',
        'companyName': 'text',
        'postalCode': 'text',
        'country': 'text',
        'nip': 'text',
        'phoneNumber': 'text',
        'email': 'email'
    };

    const history = useHistory();

    const [ emailList, setEmailList ] = useState([]);

    useEffect(() => {
        if(section && section.content) {
            setEmailList(section.content.emails);
        }
    }, [section]);

    const config = { translations: ['title'] };

    const handleOnSubmit = async formData => {
        const formToSend = utils.generateFormToSubmitValues(formData, config);

        const generatedContactFormFields = Object.keys(formToSend).map(key => {
            if(defaultContactFormOptions.includes(key) && formToSend[key]) {
                return ({
                    tag: key,
                    isRequired: formData[`${key}IsRequired`] ? formData[`${key}IsRequired`] : false,
                    type: defaultContactFormOptionsTypes[key]
                });
            }
            else {
                return null;
            }
        }).filter(field => field);

        const customFieldTranslation =  utils.generateFormToSubmitValues(formData.content.customField, { translations: ['name'] });

        customFieldTranslation.isEnabled = formToSend.customField === true;

        customFieldTranslation.isRequired = !!generatedContactFormFields.find(f => f.tag === 'customField')?.isRequired;

        const dataToSend = {
            ...formToSend,
            content: {
                fields: generatedContactFormFields,
                customField: customFieldTranslation,
                emails: emailList,
                colors: formData.content.colors
            }
        };

        const response = await addUpdatePageSection(dataToSend);

        if(typeof response === 'number') {
            history.push(`edit?sectionID=${response}&sectionType=${section.type}&location=${sectionLocation}`);

            return;
        }

        return response;
    };

    const generateInitialFields = (formFields) => {
        const initalSelectedFields = formFields.map(field => ({
            [field.tag]: true,
            [`${field.tag}IsRequired`]: field.isRequired
        }));

        return initalSelectedFields.reduce((acc, curr) => {
            return {
                ...acc,
                ...curr
            };
        }, {});
    };

    const generateInitialStructure = section => {
        const customFieldInitialValue = utils.generateFormInitialValues(
            section.content
                ? section.content.customField
                : { name: [], isEnabled: false, isRequired: false },
            { translations: ['name'] });

        return ({
            ...section,
            content: {
                ...section.content,
                customField: customFieldInitialValue
            },
            ...generateInitialFields(section.content ? section.content.fields : [])
        });
    };

    return (
        <React.Fragment>
            <div>
                <FinalForm
                    keepDirtyOnReinitialize
                    initialValues={utils.generateFormInitialValues(generateInitialStructure(section), config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form, values }) => {

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translationsHomePage.tabs.basicData,
                                            id: 'searchTerms-table',
                                            component: <HomePageSectionMainData
                                                isSectionHeightController={false}
                                                isNumberOfTilesController={false}
                                                form={form} />
                                        },
                                        {
                                            label: translationsHomePage.tabs.settings,
                                            id: 'lastPhrases-table',
                                            component:
                                                <React.Fragment>
                                                    <Row className='d-flex flex-wrap'>
                                                        <Col className='col-4'>
                                                            <Card>
                                                                <CardHeader>{translations.inputs.contactFormFieldsLabel}</CardHeader>
                                                                <CardBody>
                                                                    <ContactFormCreator
                                                                        form={form}
                                                                        values={values}
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-4'>
                                                            <Card>
                                                                <CardHeader>Kolory</CardHeader>
                                                                <CardBody>
                                                                    <FormFieldColorPicker
                                                                        button='Wybierz kolor'
                                                                        label='Kolor ramek pól formularza'
                                                                        name='content.colors.formFieldBorderColor'
                                                                    />
                                                                    <FormFieldColorPicker
                                                                        button='Wybierz kolor'
                                                                        label='Kolor tekstu nagłówków pól formularza'
                                                                        name='content.colors.formFieldTextColor'
                                                                    />
                                                                    <FormFieldColorPicker
                                                                        button='Wybierz kolor'
                                                                        label='Kolor przycisku "Wyślij"'
                                                                        name='content.colors.formButtonBackgroundColor'
                                                                    />
                                                                    <FormFieldColorPicker
                                                                        button='Wybierz kolor'
                                                                        label='Kolor tekstu przycisku "Wyślij"'
                                                                        name='content.colors.formButtonTextColor'
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-4'>
                                                            <Card>
                                                                <CardHeader>{translations.inputs.emailControlLabel}</CardHeader>
                                                                <CardBody>
                                                                    <EmailListController
                                                                        emailLabel={translations.inputs.emailLabel}
                                                                        emailList={emailList}
                                                                        setEmailList={setEmailList}
                                                                        buttonsLabels={translations.buttons}
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                        },
                                    ]}
                                />
                                <SectionEditFormButtons hideCancelButton={true} />
                            </Form>
                        );
                    }}
                />
            </div>
        </React.Fragment>
    );
};

ContactFormSection.propTypes = propTypes;

const mapDispatch = {
    addUpdatePageSection
};

export default withFormUtils(connect(null, mapDispatch)(ContactFormSection));