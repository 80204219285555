const appSidebar = {
    positions: {
        // ======================[ HANDEL ]
        ROUTE_TRADE_DASHBOARD: 'Panel handlu',
        ROUTE_TRADE_DASHBOARD_GENERAL: 'Konsola',
        ROUTE_TRADE_DASHBOARD_ORDERS: 'Lista zamówień',
        ROUTE_TRADE_DASHBOARD_STATS: 'Statystyki',
        ROUTE_TRADE_DASHBOARD_PARCELS: 'Menadżer przesyłek',
        ROUTE_TRADE_DASHBOARD_CLIENTS: 'Użytkownicy i kontrahenci',

        ROUTE_TRADE_EVENTS: 'Zdarzenia',
        ROUTE_TRADE_EVENTS_OPINIONS: 'Opinie',
        ROUTE_TRADE_EVENTS_NOTIFICATIONS: 'Powiadomienia systemowe',

        ROUTE_TRADE_ACTIONS: 'Akcje',
        ROUTE_TRADE_ACTIONS_DISCOUNTS: 'Kupony rabatowe',
        ROUTE_TRADE_ACTIONS_DISCOUNTS_EDIT: 'edycja',
        ROUTE_TRADE_ACTIONS_ADBANNERS: 'Reklamy na karcie artykułu',
        ROUTE_TRADE_ACTIONS_ADBANNERS_EDIT: 'edycja',
        ROUTE_TRADE_ACTIONS_TECHNICAL_BREAK: 'Przerwa techniczna',

        // ======================[ ARTYKUŁY I ZAMÓWIENIA ]
        ROUTE_SYNCHRONIZATIONS: 'Synchronizacja z ERP',

        ROUTE_PRODUCTS_DESCRIPTIONS_IMPORT: 'Import opisów artykułów',

        ROUTE_ASSORTMENT: 'Asortyment',
        ROUTE_ASSORTMENT_CATEGORIES: 'Kategorie',
        ROUTE_ASSORTMENT_CATEGORIES_EDIT: 'edycja',
        ROUTE_ASSORTMENT_PRODUCTS: 'Lista i ustawienia artykułów',
        ROUTE_ASSORTMENT_PRODUCTS_EDIT: 'edycja',
        ROUTE_ASSORTMENT_PRODUCTS_CATEGORY_ASSIGN: 'Przypisywanie artykułów do kategorii',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_TILE: 'Ustawienia kafelka',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_LIST: 'Ustawienia listy',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_CARD: 'Ustawienia karty artykułu',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_CARD_SECTIONS: 'Sekcje karty artykułu',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_CARD_SECTIONS_EDIT: 'edycja',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_GENERAL: 'Prezentacja',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_COMPARISON: 'Porównanie',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_QUANTITY: 'Stany magazynowe',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_UNITS: 'Jednostki miary',
        ROUTE_ASSORTMENT_PRODUCT_PRESENTATION_DELIVERY: 'Dostawa',
        ROUTE_ASSORTMENT_SHIPMENT_SERVICES: 'Usługi dostawy',
        ROUTE_ASSORTMENT_PAYMENT_SERVICES: 'Usługi płatności',
        ROUTE_ASSORTMENT_OTHER_SERVICES: 'Usługi pozostałe',
        ROUTE_ASSORTMENT_SEARCH_ENGINE: 'Wyszukiwarka i filtry',
        ROUTE_ASSORTMENT_MANUFACTURERS: 'Producenci',
        ROUTE_ASSORTMENT_MANUFACTURERS_EDIT: 'edycja',
        ROUTE_ASSORTMENT_ATTRIBUTES: 'Atrybuty',
        ROUTE_ASSORTMENT_ATTRIBUTES_EDIT: 'edycja',
        ROUTE_ASSORTMENT_FEATURES: 'Cechy',
        ROUTE_ASSORTMENT_FEATURES_EDIT: 'edycja',

        ROUTE_ORDERS: 'Zamówienia',
        ROUTE_ORDERS_GENERAL: '>>>Parametry zamówień<<<',
        ROUTE_ORDERS_SHIPPING_AND_PAYMENT_GENERAL: 'Parametry form dostawy i płatności',
        ROUTE_ORDERS_SHIPPING_METHODS: 'Formy dostawy',
        ROUTE_ORDERS_PAYMENT_METHODS: 'Formy płatności',
        ROUTE_ORDERS_STATUSES: 'Statusy zamówień',
        ROUTE_ORDERS_GREETINGS: 'Podziękowania',
        ROUTE_ORDERS_NOTIFYCATIONS: '>>>Powiadomienia<<<',
        ROUTE_ORDERS_CART: '>>>Koszyk<<<',
        ROUTE_ORDERS_EDIT: 'edycja',
        ROUTE_ORDERS_DETAILS: 'szczegóły',

        // ======================[ MENU ]
        ROUTE_MENU: 'Menu',
        ROUTE_MENU_POSITIONS: 'Pozycje menu',
        ROUTE_MENU_POSITIONS_EDIT: 'edycja',
        ROUTE_MENU_CUSTOM_PAGES: 'Strony własne',
        ROUTE_MENU_CUSTOM_PAGES_EDIT: 'edycja',
        ROUTE_MENU_PREDEFINED_SEARCH: 'Predefiniowane filtrowanie artykułów',
        ROUTE_MENU_PREDEFINED_SEARCH_EDIT: 'edycja',
        ROUTE_MENU_SECTIONS: '>>>Sekcje<<<',
        ROUTE_MENU_FOOTER: 'Stopka',

        // ======================[ GRAFIKA ]
        ROUTE_GRAPHICS_SETTINGS: 'Ustawienia',
        ROUTE_GRAPHICS_SETTINGS_MEDIA_LIBRARY: 'Biblioteka mediów',
        ROUTE_GRAPHICS_SETTINGS_LAYOUT: 'Skórka',
        ROUTE_GRAPHICS_SETTINGS_LAYOUT_EDIT: 'edycja',
        ROUTE_GRAPHICS_SETTINGS_LOGO: 'Logo',
        ROUTE_GRAPHICS_SETTINGS_PROMOTIONBAR: 'Pasek informacyjny',
        ROUTE_GRAPHICS_SETTINGS_PLACEHOLDER_IMAGES: 'Zdjęcie zastępcze artykułu',
        ROUTE_GRAPHICS_SETTINGS_BUTTONS: 'Definicje przycisków',
        ROUTE_GRAPHICS_SETTINGS_BUTTONS_EDIT: 'edycja',
        ROUTE_GRAPHICS_SETTINGS_IMAGE_QUALITY: 'Jakość grafik',
        ROUTE_GRAPHICS_SETTINGS_FONTS: 'Czcionki',
        ROUTE_GRAPHICS_MAIN_PAGE: 'Strona główna (startowa)',
        ROUTE_GRAPHICS_MAIN_PAGE_EDIT: 'edycja',
        ROUTE_GRAPHICS_MAIN_PAGE_GENERAL: 'Ogólne',
        ROUTE_GRAPHICS_MAIN_PAGE_SECTIONS: 'Sekcje',
        ROUTE_GRAPHICS_MAIN_PAGE_METATAGS: 'Metatagi',

        ROUTE_GRAPHICS_REGISTRATION_PAGE: 'Rejestracja użytkownika',
        ROUTE_GRAPHICS_REGISTRATION_SUMMARY_PAGE_SECTIONS: 'Podsumowanie',
        ROUTE_GRAPHICS_REGISTRATION_SUMMARY_PAGE_EDIT: 'edycja',
        ROUTE_GRAPHICS_REGISTRATION_CONFIRMATION_PAGE_SECTIONS: 'Informacja po potwierdzeniu adresu e-mail',
        ROUTE_GRAPHICS_REGISTRATION_CONFIRMATION_PAGE_EDIT: 'edycja',

        ROUTE_GRAPHICS_CART_PAGE: 'Koszyk',
        ROUTE_GRAPHICS_CART_PAGE_SECTIONS: 'Sekcje',
        ROUTE_GRAPHICS_CART_PAGE_EDIT: 'edycja',

        // ======================[ KOMUNIKACJA ]
        ROUTE_COMMUNICATION_EMAIL: 'E-mail',
        ROUTE_COMMUNICATION_EMAIL_SERVER: 'Serwery pocztowe',
        ROUTE_COMMUNICATION_EMAIL_SERVER_EDIT: 'edycja',
        ROUTE_COMMUNICATION_EMAIL_TEMPLATES: 'Szablony wiadomości e-mail',
        ROUTE_COMMUNICATION_EMAIL_TABLE_DEFINITIONS: 'Tabele',
        ROUTE_COMMUNICATION_EMAIL_TABLE_DEFINITION: 'Definicja wyglądu tabeli',
        ROUTE_COMMUNICATION_SMS: 'SMS',
        ROUTE_COMMUNICATION_SMS_GATE: 'Bramka SMS',
        ROUTE_COMMUNICATION_SMS_TEMPLATES: 'Szablony wiadomości SMS',

        // ======================[ RODO ]
        ROUTE_RODO_AGREEMENTS: 'Zgody - definicje',
        ROUTE_RODO_AGREEMENTS_EDIT: 'edycja',
        ROUTE_RODO_AGREEMENTS_REGISTRY: 'Rejestr zgód',
        ROUTE_RODO_COOKIES_AGREEMENTS: 'Cookies',
        ROUTE_RODO_COOKIES_AGREEMENTS_EDIT: 'edycja',

        // ======================[ JĘZYKI I KRAJE ]
        ROUTE_LANGUAGES_AND_COUNTRIES_LANGUAGES: 'Języki sklepu',
        ROUTE_LANGUAGES_AND_COUNTRIES_COUNTRIES: 'Lista państw',
        ROUTE_LANGUAGES_AND_COUNTRIES_CURRENCIES: 'Waluty',
        ROUTE_LANGUAGES_AND_COUNTRIES_CURRENCIES_EDIT: 'edycja',
        ROUTE_LANGUAGES_AND_COUNTRIES_EXCHANGERATES: 'Kursy walut',
        ROUTE_LANGUAGES_AND_COUNTRIES_EXCHANGERATES_EDIT: 'edycja',
        ROUTE_LANGUAGES_AND_COUNTRIES_REGIONS: 'Regiony',
        ROUTE_LANGUAGES_AND_COUNTRIES_REGIONS_EDIT: 'edycja',
        ROUTE_LANGUAGES_AND_COUNTRIES_TRANSLATIONS: 'Tłumaczenia',

        // ======================[ INTEGRACJE ]
        ROUTE_INTEGRATIONS: 'Integracje',
        ROUTE_INTEGRATIONS_ONLINE_PAYMENTS: 'Płatności online',
        ROUTE_INTEGRATIONS_ONLINE_PAYMENTS_P24: 'Przelewy24',
        ROUTE_INTEGRATIONS_ONLINE_PAYMENTS_PAYU: 'PayU',
        ROUTE_INTEGRATIONS_COURIERS: 'Kurierzy',
        ROUTE_INTEGRATIONS_COURIERS_INPOST: 'InPost paczkomaty',
		ROUTE_INTEGRATIONS_COURIERS_APACZKA: 'APaczka',
		ROUTE_INTEGRATIONS_COURIERS_FURGONETKA: 'Furgonetka',
        ROUTE_INTEGRATIONS_SOCIAL: 'Społecznościowe',
        ROUTE_INTEGRATIONS_SOCIAL_FACEBOOK: 'Facebook pixel',
        ROUTE_INTEGRATIONS_COMPARISIONS: 'Porównywarki',
        ROUTE_INTEGRATIONS_COMPARISIONS_CENEO: 'Ceneo',
        ROUTE_INTEGRATIONS_COMPARISIONS_GOOGLE: 'Google merchant',
        ROUTE_INTEGRATIONS_MARKETING: 'Marketing',
        ROUTE_INTEGRATIONS_MARKETING_GOOGLE: 'Google Analytics',
        ROUTE_INTEGRATIONS_MARKETING_SALES_MENAGO: 'SALESmenago',
        ROUTE_INTEGRATIONS_MARKETING_FRESHMAIL: 'Freshmail',
        ROUTE_INTEGRATIONS_MARKETING_INSPECTLET: 'Inspectlet',
        ROUTE_INTEGRATIONS_REVIEWS: 'Opinie',
        ROUTE_INTEGRATIONS_REVIEWS_GOOGLE: 'Google',
        ROUTE_INTEGRATIONS_REVIEWS_OPINEO: 'Opineo',
        ROUTE_INTEGRATIONS_COMMUNICATION: 'Kanały komunikacji',
        ROUTE_INTEGRATIONS_COMMUNICATION_CHAT: 'Chat',
        ROUTE_INTEGRATIONS_COMMUNICATION_SMS: 'Serwer SMS',
        ROUTE_INTEGRATIONS_OTHER: 'Inne',
        ROUTE_INTEGRATIONS_OTHER_RECAPTCHA: 'reCaptcha',
        ROUTE_INTEGRATIONS_OTHER_GOOGLE_SEARCH_CONSOLE: 'Google search console',

        // ======================[ SEO ]
        ROUTE_SEO_SITEMAP: 'Google sitemap',
        ROUTE_SEO_IMAGES: 'Zdjęcia',
        ROUTE_SEO_ADWORDS: '>>>Google AdWords<<<',
        ROUTE_SEO_METATAGS: 'Metatagi',
        ROUTE_SEO_METATAGS_CART: 'Koszyk',
        ROUTE_SEO_METATAGS_ORDER_AUTHORIZE: 'Zamówienie - logowanie',
        ROUTE_SEO_METATAGS_ORDER_GUEST: 'Zamówienie - gość',
        ROUTE_SEO_METATAGS_ORDER_SHIPMENT: 'Zamówienie - dostawa',
        ROUTE_SEO_METATAGS_ORDER_PAYMENT: 'Zamówienie - płatność',
        ROUTE_SEO_METATAGS_ORDER_SUMMARY: 'Zamówienie - podsumowanie',
        ROUTE_SEO_METATAGS_ORDER_GREETINGS: 'Zamówienie - podziękowanie',
        ROUTE_SEO_METATAGS_ORDER_INACTIVE: 'Zamówienie nieaktywne',
        ROUTE_SEO_METATAGS_FAVOURITES: 'Ulubione',
        ROUTE_SEO_METATAGS_404: '404',
        ROUTE_SEO_METATAGS_REGISTRATION: 'Rejestracja',
        ROUTE_SEO_METATAGS_LOGIN: 'Strona logowania',
        ROUTE_SEO_METATAGS_PASSWORD_RECOVERY: 'Odzyskiwanie hasła',
        ROUTE_SEO_METATAGS_PASSWORD_SET_NEW_PASSWORD: 'Ustawianie nowego hasła',
        ROUTE_SEO_METATAGS_COMPARISION: 'Porównanie',
        ROUTE_SEO_METATAGS_CONTACT: 'Kontakt',
        ROUTE_SEO_METATAGS_USERPROFILE_ACCOUNT: 'Profil - konto',
        ROUTE_SEO_METATAGS_USERPROFILE_BASIC: 'Profil - podstawowe',
        ROUTE_SEO_METATAGS_USERPROFILE_ADDRESSBOOK: 'Profil - adresy',
        ROUTE_SEO_METATAGS_USERPROFILE_ORDERS: 'Profil - zamówienia',
        ROUTE_SEO_METATAGS_USERPROFILE_SETTINGS: 'Profil - ustawienia',
        ROUTE_SEO_METATAGS_GLOBAL_SEARCH: 'Wyszukiwanie globalne',
        ROUTE_SEO_OWNCODE: 'Własny kod',

        // ======================[ USTAWIENIA GLOBALNE ]
        ROUTE_GLOBAL_SETTINGS_CATALOGUE_MODE: 'Tryb katalogu',
        ROUTE_GLOBAL_SETTINGS_404: 'Strona 404',
        ROUTE_GLOBAL_SETTINGS_TAX_RATES: 'Stawki VAT',
        ROUTE_GLOBAL_SETTINGS_LOGIN: 'Logowanie i rejestracja',

        // ======================[ UŻYTKOWNICY PANELU ]
        ROUTE_PANEL_USERS_ACCOUNT_SETTINGS: 'Ustawienia konta',
        ROUTE_PANEL_USERS_ADMIN: '>>>Admin<<<',
        ROUTE_PANEL_USERS_LIST: '>>>Lista użytkowników<<<',
        ROUTE_PANEL_USERS_EDIT: 'Edycja użytkownika',

        // ======================[ DODATKOWE ]
        ROUTE_EXTRAS: 'Dodatkowe',
        ROUTE_EXTRAS_GENERAL: 'Ogólne',
        ROUTE_EXTRAS_CATEGORIES: 'Kategorie',
    },
    sections: {
        trade: 'HANDEL',
        articlesAndOrders: 'ARTYKUŁY I ZAMÓWIENIA',
        menu: 'MENU',
        graphics: 'GRAFIKA',
        communication: 'KOMUNIKACJA',
        rodo: 'RODO',
        languagesAndCountries: 'JĘZYKI I KRAJE',
        integrations: 'INTEGRACJE',
        seo: 'SEO',
        globalSettings: 'USTAWIENIA GLOBALNE',
        panelUsers: 'UŻYTKOWNICY PANELU',
        extras: 'DODATKOWE'
    }
};

export default appSidebar;