import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { addUpdatePageSection } from '../../../../redux/methods/homePage';

import { Form as FinalForm } from 'react-final-form';
import { Form, Row } from 'reactstrap';

import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import FormFieldTranslationsQuill from '../../../../components/formFields/FormFieldTranslationsQuill';
import FormFieldTextarea from '../../../../components/formFields/FormFieldTextarea';

import HomePageSectionMainData from '../HomePageSectionMainData';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import styles from './index.module.scss';

import Tabulator from '../../../../components/Tabulator';

const propTypes = {
    currentLanguage: PropTypes.string,
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object,
};

const TinySection = ({ utils, section, addUpdatePageSection, sectionLocation }) => {

    const history = useHistory();
    const intl = useIntl();
    const translations = intl.messages.homePage.promoSections.sections;
    const translationsHomePage = intl.messages.homePage;

    const config = { translations: ['title', 'content'], date: ['activeFrom', 'activeTo'] };

    const handleOnSubmit = async formData => {
        const response = await addUpdatePageSection(utils.generateFormToSubmitValues(formData, config));

        if(typeof response === 'number') {
            history.push(`edit?sectionID=${response}&sectionType=${section.type}&location=${sectionLocation}`);

            return;
        }

        return response;
    };

    return section ? (
        <div className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(section, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, form }) => {

                        const { values } = form.getState();

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translationsHomePage.tabs.basicData,
                                            id: 'searchTerms-table',
                                            component: <HomePageSectionMainData
                                                isSectionHeightController={true}
                                                isNumberOfTilesController={false}
                                                form={form} />
                                        },
                                        {
                                            label: translationsHomePage.tabs.settings,
                                            id: 'lastPhrases-table',
                                            component:
                                                <React.Fragment>
                                                    <Row className='mt-2 px-3'>
                                                        <FormFieldTranslationsQuill
                                                            form={form}
                                                            utils={utils}
                                                            propertyName="content"
                                                            header={translations.inputs.content}
                                                            customStyles={values.css}
                                                        />
                                                    </Row>
                                                    <Row className='mt-2 px-3'>
                                                        <FormFieldTextarea
                                                            form={form}
                                                            utils={utils}
                                                            name="css"
                                                            className='w-100'
                                                            rows={40}
                                                            label={translations.inputs.css}
                                                        />
                                                    </Row>
                                                </React.Fragment>
                                        },
                                    ]}
                                />

                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.button,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        }
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    ) : null;
};

TinySection.propTypes = propTypes;

const mapDispatch = {
    addUpdatePageSection,
};

export default withFormUtils(connect(null, mapDispatch)(TinySection));