const homePage = {
    tabs:{
        basicData: 'Dane podstawowe',
        settings: 'Ustawienia szczegółowe'
    },
    mainFormData: {
        dateFrom: 'Aktywna od',
        dateTo: 'Aktywna do',
        activeInLanguages: 'Aktywna w językach',
        name: 'Nazwa sekcji*',
        desktopActive: 'Aktywna na ekranie komputera',
        tabletActive: 'Aktywna na tablecie',
        mobileActive: 'Aktywna na telefonie',
        type: 'Typ sekcji',
        desktopSettings: 'Ustawienia dla ekranu komputera (powyżej 1290px)',
        tabletSettings: 'Ustawienia dla tabletu (powyżej 700px)',
        mobileSettings: 'Ustawienia dla telefonu',
        globalSettings: 'Ustawienia globalne',
        desktopHeight: 'Wysokość sekcji na ekranie komputera',
        tabletHeight: 'Wysokość sekcji na ekranie tabletu',
        mobileHeight: 'Wysokość sekcji na ekranie telefonu',
        numberOfTiles: 'Ilość kafelków w rzędzie:',
        sectionTitle: 'Tytuł sekcji',
        enableTitle: 'Aktywny tytuł',
        pxLabel: 'px',
        marginTop: 'Margines górny',
        marginBottom: 'Margines dolny',
        backgroundColorButton: 'Wybierz kolor',
        backgroundColor: 'Kolor tła',
        fullWidth: 'Sekcja zajmie całą szerokość ekranu'
    },
    promoSections: {
        title: 'Sekcje promocyjne',
        sections: {
            left: 'Lewo',
            right: 'Prawo',
            center: 'Środek',
            bottom: 'Dół',
            top: 'Góra',
            inputs: {
                isActive: 'Aktywność',
                file: {
                    edit: 'PODMIEŃ ZDJĘCIE',
                    remove: 'USUŃ ZDJĘCIE',
                    uplaod: 'PRZEŚLIJ ZDJĘCIE',
                },
                header: 'Nagłówek sekcji',
                content: 'Treść sekcji',
                css: 'Kod css',
                button: 'Treść buttona',
                horizontalAlignment: 'Pozycja przycisku w poziomie',
                verticalAlignment: 'Pozycja przycisku w pionie',
                link: 'Link tła',
                linkHelper: 'Aby link był dostępny, musisz podać treść przycisku.',
                photoLocation: 'Lokalizacja zdjęcia',
                shadeColor: 'Kolor przesłony',
                buttonFontColor: 'Kolor fontu przycisku',
                buttonColor: 'Kolor tła przycisku',
                opacityRange: 'Stopień przyciemnienia',
                manufacturer: 'Producent',
                tag: 'Tag',
                category: 'Kategoria',
                selectCategory: 'Wybierz kategorię',
                changeCategory: 'Zmień kategorię',
                selectNone: 'Brak',
                isFull: 'Sekcja bez margiensów i na całą szerokosć',
                searchInProductCategory: 'Wyszukuj w kategorii produktu',
                searchInProductManufacturer: 'Wyszukuj w artykułach producenta produktu'
            },
            charLimitExceeded: 'Ilość znaków została przekroczona. Zmniejsz ilość znaków aby kontynuować',
            button: 'ZAPISZ ZMIANY',
            one: {
                title: 'Sekcja 1',
            },
            two: {
                title: 'Sekcja 2',
            },
            firstImageLabel: 'Pierwszy kafelek',
            secondImageLabel: 'Drugi kafelek'
        }
    },
    photoWithTextSections: {
        title: 'Sekcje promocyjne',
        sections: {
            left: 'Lewo',
            right: 'Prawo',
            center: 'Środek',
            bottom: 'Dół',
            top: 'Góra',
            inputs: {
                file: {
                    edit: 'PODMIEŃ ZDJĘCIE',
                    remove: 'USUŃ ZDJĘCIE',
                    uplaod: 'PRZEŚLIJ ZDJĘCIE',
                },
                linkBackground: 'Link tło',
                linkButton: 'Link przycisk',
                buttonText: 'Tekst na przycisku',
                horizontalAlignment: 'Pozycja przycisku w poziomie',
                verticalAlignment: 'Pozycja przycisku w pionie',
                buttonColorText: 'Kolor czcionki na przycisku',
                buttonColorBackground: 'Kolor tła przycisku',
                content: 'Treść',
                imageAlternativeText: 'Tekst alternatywny dla zdjęcia'

            },
            button: 'ZAPISZ ZMIANY',
        }
    },
    videoSection: {
        title: 'Sekcja video',
        sections: {
            left: 'Lewo',
            right: 'Prawo',
            center: 'Środek',
            bottom: 'Dół',
            top: 'Góra',
            fullScreen: 'Cały ekran',
            inputs: {
                file: {
                    edit: 'PODMIEŃ VIDEO',
                    remove: 'USUŃ VIDEO',
                    upload: 'PRZEŚLIJ VIDEO',
                },
                linkBackground: 'Link tło',
                linkButton: 'Link przycisk',
                buttonText: 'Tekst na przycisku',
                horizontalAlignment: 'Pozycja przycisku w poziomie',
                verticalAlignment: 'Pozycja przycisku w pionie',
                buttonColorText: 'Kolor czcionki na przycisku',
                buttonColorBackground: 'Kolor tła przycisku',
                content: 'Treść',
                autoplay: 'Autoodtwarzanie',
                loop: 'Zapętlenie',
                mute: 'Wyciszenie',
                videoPosition: 'Położenie video'
            },
            button: 'ZAPISZ ZMIANY',
        }
    },
    metatagsSectionTitle: 'Metatagi Strony Głównej',
    general: {
        title: 'Ustawienia ogólne Strony Głównej',
        labels: {
            backgroundColor: 'Kolor tła'
        },
        buttons: {
            select: 'Wybierz'
        }
    },
    sections: {
        actions: {
            add: 'DODAJ NOWĄ SEKCJĘ',
        },
        sections: {
            sliderWithButton: 'Banner',
            articlesWithBookmarks: 'Lista artykułów',
            articles: 'Zakładki artykułów',
            photoWithText: 'Zdjęcie z tekstem',
            tiny: 'Treść własna',
            promoted: 'Sekcja promowana',
            doubleImage: 'Podwójna sekcja promowana',
            banner: 'Banner',
            manufacturer: 'Karuzela producentów',
            video: 'Sekcja Video',
            animatedBanner: 'Animowany banner',
            contactForm: 'Formularz kontaktowy',
            map: 'Mapa'
        },
        additionModal: {
            confirm: 'DODAJ',
            cancel: 'ANULUJ',
            header: 'Wybór typu sekcji',
            body: 'Podgląd sekcji'
        },
        sortButtons: {
            caption: 'Pokaż kolejność / aktywność:',
            desktop:'Dla ekranu komputera',
            tablet:'Dla tabletu',
            mobile:'Dla telefonu'
        },
        titles: {
            desktop:'Sekcje dla ekranu komputera',
            tablet:'Sekcje dla tabletu',
            mobile:'Sekcje dla telefonu'
        }
    },
    table: {
        headers: {
            order: 'Kolejność',
            name: 'Nazwa',
            type: 'Typ sekcji',
            desktopActive: 'Aktywna na ekranie komputera',
            tabletActive: 'Aktywna na tablecie',
            mobileActive: 'Aktywna na telefonie',
            activeFrom: 'Aktwyna od',
            activeTo: 'Aktywna do',
            activeInLanguages: 'Aktywna w językach',
            actions: 'Akcje',
        },
        translations: {
            tooltips: {
                edit: 'Edytuj',
                remove: 'Usuń',
                copy: 'Kopiuj'
            },
            deleteModal: {
                header: 'Usuwanie predefiniowanego wyszukiwania',
                body: 'Czy na pewno chcesz usunąć predefiniowane wyszukiwanie?',
                confirm: 'USUŃ',
                cancel: 'ANULUJ',
            },
            emptyTable: 'Brak predefiniowanych wyszukań',
        },
    },
    edit: {
        title: 'Edycja sekcji',
        productAssigner: 'Przypisywanie produktów',
        categoryAssigner: 'Przypisywanie kategorii',
        bannerImages: 'Lista slajdów',
        animatedBannerImages: 'Lista slajdów',
        information: 'Zapisz zmiany w poniższym formularzu przed przejściem do kolejnych zakładek. Po zapisaniu zmian zakładki zostaną odblokowane.'
    },
    manufacturersSection: {
        main: {
            button: 'ZAPISZ ZMIANY',
            title: 'Ustawienia ogólne',
            amountToShow: 'Liczba producentów na jednej stronie'
        },
        manufacturers: {
            title: 'Wybór producentów',
            tooltips: {
                edit: 'Edytuj producenta',
                remove: 'Odłącz producenta',
            },
            deleteModal: {
                header: 'Odłączanie producenta',
                body:'Czy chcesz odłączyć producenta?',
                confirm: 'ODŁĄCZ',
                cancel: 'ANULUJ',
            },
            emptyTable: 'Brak producentów',
            headers: {
                logo: 'Logo',
                name: 'Nazwa',
                order: 'Kolejność',
                active: 'Aktywność',
                actions: 'Akcje',
            },
            nameFiller: '[BRAK NAZWY]',
            buttons: {
                add: 'PRZYPISZ PRODUCENTA',
                remove: 'ODŁĄCZ ZAZNACZONYCH',
            },
            modal: {
                header: 'Przypisywanie producentów',
                cancel: 'ANULUJ',
                confirm: 'PRZYPISZ',
            }
        }
    },
    contactFormSection: {
        isRequiredLabel: 'Wymagane',
        customFieldNameLabel: 'Nazwa pola własnego',
        inputs: {
            contactFormFieldsLabel: 'Lista widocznych pól formularza kontaktowego',
            emailControlLabel: 'Lista adresów e-mail na które zostanie wysłana wiadomość',
            emailLabel: 'Adres e-mail'
        },
        fields: {
            nameSurname: 'Imię i Nazwisko',
            firstName: 'Imię',
            surname: 'Nazwisko',
            companyName: 'Nazwa firmy',
            postalCode: 'Kod pocztowy',
            country: 'Kraj',
            nip: 'NIP',
            phoneNumber: 'Telefon',
            email: 'E-mail',
            content: 'Treść zapytania',
            customField: 'Pole własne'
        },
        buttons: {
            removeEmail: 'Usuń',
            addEmail: 'Dodaj adres e-mail'
        }
    },
    mapSection: {
        inputs: {
            mapSize: 'Rozmiar mapy',
            chooseMapSize: 'Wybierz rozmiar mapy'
        },
        mapSizes: {
            Small: 'XS - 300px',
            Medium: 'S - 500px',
            Large: 'M - 700px',
            VeryLarge: 'L - 1024px',
            Huge: 'XL - 1200px',
            FullScreen: 'XXL - pełna szerokość ekranu'
        },
        mapGuides: '- Kliknij dwa razy w wybrane miejsce aby ustawić znacznik',
    }
};

export default homePage;