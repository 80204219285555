import React from 'react';
import PropTypes from 'prop-types';

import { generateNonIdsOptionsForDropdown } from '../../../utils/components';

import { Alert, Label, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import FormFieldWithDropdown from '../../../components/formFields/FormFieldWithDropdown';
import FormFieldWithCheckbox from '../../../components/formFields/FormFieldWithCheckbox';
import Dropdown from '../../../components/Dropdown';

import { itemCountTile } from '../../../config';

import ArticlePresentationCardTable from '../../../components/tables/ArticlePresentationCardTable';

import styles from './index.module.scss';

const propTypes = {
    translations: PropTypes.object,
};

const ProductTileDeviceSettings = ({
    prefix,
    translations,
    translationsAdditionalFields,
    numbersDropdownOptions,
    manufacturerFields,

    selectedAdditionalFieldIds,
    additionalFieldSelectionLabel,
    handleAdditionalFieldsSelection,

    selectedAdditionalField2Ids,
    additionalField2SelectionLabel,
    handleAdditionalFields2Selection,

    selectedManufacturerFieldIds,
    manufacturerSelectionLabel,
    handleManufacturerFieldSelection,

    count,
    singleFillerCount,
    doubleFillerCount,
    allTagAttributesOptions,
    allTagAttributes2Options,
    additionalFieldsToEdit,
    setAdditionalFieldsToEdit,
    values
}) => {
    return additionalFieldsToEdit && translationsAdditionalFields ? (
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        {translations.labels.generalSettings}
                    </CardHeader>
                    <CardBody>
                        <div className='mt-3'>
                            <FormFieldWithDropdown
                                currentSelectionLabel={count.label}
                                name={`${prefix}.itemCount`}
                                label={translations.labels.count}
                                options={itemCountTile}
                            />
                        </div>
                        <Alert className='mt-3' color='danger'>
                            {translations.info}
                        </Alert>
                        <div className='mt-3'>
                            <FormFieldWithDropdown
                                currentSelectionLabel={singleFillerCount?.label}
                                name={`${prefix}.singleFillerCount`}
                                label={translations.labels.singleFillerCount}
                                options={numbersDropdownOptions}
                            />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithDropdown
                                currentSelectionLabel={doubleFillerCount?.label}
                                name={`${prefix}.doubleFillerCount`}
                                label={translations.labels.doubleFillerCount}
                                options={numbersDropdownOptions}
                            />
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        {translations.labels.optionalFields}
                    </CardHeader>
                    <CardBody>
                        <div className='mt-3'>
                            <Label>{translations.labels.imageTags}</Label>
                            <Dropdown
                                name={`${prefix}.additionalField`}
                                withCheckboxes={true}
                                options={allTagAttributesOptions}
                                performSelection={handleAdditionalFieldsSelection}
                                checkedIds={selectedAdditionalFieldIds}
                                currentSelectionLabel={additionalFieldSelectionLabel}
                            />
                        </div>
                        <div className='mt-3'>
                            <Label>{translations.labels.imageTags2}</Label>
                            <Dropdown
                                name={`${prefix}.additionalField2`}
                                withCheckboxes={true}
                                options={allTagAttributes2Options}
                                performSelection={handleAdditionalFields2Selection}
                                checkedIds={selectedAdditionalField2Ids}
                                currentSelectionLabel={additionalField2SelectionLabel}
                            />
                        </div>
                        <div className='mt-3'>
                            <div className={styles.dropdown}>
                                <Label>{translations.labels.manufacturerField}</Label>
                                <Dropdown
                                    name={`${prefix}.manufacturerField`}
                                    withCheckboxes={true}
                                    options={manufacturerFields ? generateNonIdsOptionsForDropdown(manufacturerFields, translations.manufacturerFields) : []}
                                    performSelection={handleManufacturerFieldSelection}
                                    checkedIds={selectedManufacturerFieldIds}
                                    currentSelectionLabel={manufacturerSelectionLabel}
                                />
                            </div>
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showReviews`}
                                label={translations.labels.showReviews} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showQuantity`}
                                label={translations.labels.showQuantity} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showAddToFavourites`}
                                label={translations.labels.showAddToFavourites} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showAddToComparision`}
                                label={translations.labels.showAddToComparision} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showRelated`}
                                label={translations.labels.showRelated} />
                        </div>
                        <div className='mt-3'>
                            <FormFieldWithCheckbox
                                name={`${prefix}.showAddToCart`}
                                label={translations.labels.showAddToCart} />
                        </div>
                        {values[prefix].showAddToCart &&
                            <div className='mt-3'>
                                <FormFieldWithCheckbox
                                    name={`${prefix}.showQuantitySelection`}
                                    label={translations.labels.showQuantitySelection} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card>
                    <CardHeader>
                        {translations.labels.additionalFields}
                    </CardHeader>
                    <CardBody>
                        <ArticlePresentationCardTable
                            type='tile'
                            device={prefix}
                            parameters={additionalFieldsToEdit}
                            setParameters={setAdditionalFieldsToEdit}
                            headerLabels={translationsAdditionalFields.headerLabels}
                            translations={translationsAdditionalFields.table.labels}
                            setIsEdited={() => {}}
                            checkLimit={8}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    ) : null;
};

ProductTileDeviceSettings.propTypes = propTypes;

export default ProductTileDeviceSettings;