import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { selectStoreCurrentLanguage } from '../../../redux/selectors/storeLocale';
import { changeStoreChosenLanguage } from '../../../redux/methods/storeLocale';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactCountryFlag from 'react-country-flag';

import { translateIso } from '../../../utils/locale';
import { getLanguageNativeName } from '../../../utils/storeLocale';

import styles from './index.module.scss';

const propTypes = {
    storeAvailableLanguages: PropTypes.arrayOf(PropTypes.object),
    storeCurrentLanguage: PropTypes.string.isRequired,
    changeStoreChosenLanguage: PropTypes.func,
    className: PropTypes.string,
    isLabelNeeded: PropTypes.bool,
};

const LanguageDropdown = ({ storeAvailableLanguages, storeCurrentLanguage, changeStoreChosenLanguage, className, isLabelNeeded }) => {
    const intl = useIntl();
    const translations = intl.messages.storeLocale.languageDropdownSelector;

    const [ isOpen, setIsOpen ] = useState(false);

    const toggleIsOpen = () => setIsOpen(!isOpen);

    const handleLanguageSelection = languageIso => () => {
        if (languageIso !== storeCurrentLanguage) {
            changeStoreChosenLanguage(languageIso);
        }
    };

    const FlagItem = ({ iso, name, isSelected }) =>
        <span className={clsx(styles.flagItem, isSelected && styles.flagItemSelected)}>
            <ReactCountryFlag
                className={styles.flagItemFlag}
                svg
                countryCode={iso}
            />
            <span className={styles.flagItemText}>{name}</span>
            { isSelected && <i className={`cui-check icons font-1xl ${styles.flagItemCheck}`}/> }
        </span>
    ;

    return (
        <div className={clsx(styles.container, className)}>
            {isLabelNeeded && <span className={styles.label}>{translations.label}</span>}
            <Dropdown isOpen={isOpen} toggle={toggleIsOpen}>
                <DropdownToggle color="primary" className={styles.dropdownToggle} caret>
                    <FlagItem
                        iso={translateIso(storeCurrentLanguage.toUpperCase())}
                        name={getLanguageNativeName(storeAvailableLanguages, storeCurrentLanguage)}
                    />
                </DropdownToggle>
                <DropdownMenu>
                    {
                        storeAvailableLanguages.map((languageObject, i) => {
                            const isSelected = languageObject.iso.toUpperCase() === storeCurrentLanguage.toUpperCase();
                            return (
                                <DropdownItem
                                    className={clsx(isSelected && styles.flagItemWrapper)}
                                    key={`langItem-${i}`}
                                    onClick={handleLanguageSelection(languageObject.iso)}
                                >
                                    <FlagItem
                                        isSelected={isSelected}
                                        iso={translateIso(languageObject.iso.toUpperCase())}
                                        name={languageObject.nativeName}
                                    />
                                </DropdownItem>
                            );
                        })
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

LanguageDropdown.propTypes = propTypes;

const mapStateToProps = state => ({
    storeAvailableLanguages: state.storeLocale.storeAvailableLanguages,
    storeCurrentLanguage: selectStoreCurrentLanguage(state)
});

const mapDispatch = {
    changeStoreChosenLanguage
};

export default connect(mapStateToProps, mapDispatch)(LanguageDropdown);