import { createSelector } from '@reduxjs/toolkit';

const selectStoreChosenLanguage = state => state.storeLocale.storeChosenLanguage;
const selectStoreDefaultLanguage = state => state.storeLocale.storeDefaultLanguage;
const selectStoreAvailableLanguages = state => state.storeLocale.storeAvailableLanguages;

export const selectStoreCurrentLanguage = createSelector(
    selectStoreChosenLanguage,
    selectStoreDefaultLanguage,
    (chosenLanguage, defaultLanguage) => chosenLanguage || defaultLanguage
);

export const selectStoreCurrentLanguageId = createSelector(
    selectStoreAvailableLanguages,
    selectStoreCurrentLanguage,
    (availableLanguages, chosenLanguage) => {
        const matchingLanguage = availableLanguages.find(language => language.iso.toLowerCase() === chosenLanguage.toLowerCase());

        return matchingLanguage && matchingLanguage.id;
    }
);

export const selectStoreAvailableIsoList = createSelector(
    selectStoreAvailableLanguages,
    availableLanguages => availableLanguages.map(language => language.iso)
);