import PropTypes from 'prop-types';

import { attributeSettingsTagEnum } from '../enums/attributes';

import { actionsEnum } from '../enums/shareable';

export const formUtilsHocPropTypes = PropTypes.shape({
    allLanguages: PropTypes.func,
    currentLanguage: PropTypes.func,
    generateFormInitialValues: PropTypes.func,
    generateFormToSubmitValues: PropTypes.func
});

export const attributeSettingsToEditPropTypes = PropTypes.shape({
    attributeId: PropTypes.number,
    isExpanded: PropTypes.bool,
    minimumValue: PropTypes.number,
    maximumValue: PropTypes.number,
    precistion: PropTypes.number
});

export const translationPropTypes = PropTypes.shape({
    iso: PropTypes.string,
    value: PropTypes.string
});

export const stringOrNumberPropTypes = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
]);

export const imageAlignmentsPropTypes = PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['Banner', 'Tile']),
    horizontalAlignment: PropTypes.oneOf(['Center', 'Left', 'Right']),
    verticalAlignment: PropTypes.oneOf(['Center', 'Top', 'Bottom'])
}));

export const categoryToEditPropTypes = PropTypes.shape({
    categoryId: stringOrNumberPropTypes,
    status: stringOrNumberPropTypes,
    name: PropTypes.arrayOf(translationPropTypes)
});

export const flattenedCategoryTreeItemPropTypes = PropTypes.shape({
    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    name: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                iso: PropTypes.string,
                value: PropTypes.string
            })
        ),
        PropTypes.string
    ]),
    depth: PropTypes.number,
    quantity: PropTypes.number,
    parent: stringOrNumberPropTypes,
    subItems: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.number,
    orderIndex: PropTypes.number,
    metatags: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: stringOrNumberPropTypes,
            type: PropTypes.string,
            translations: PropTypes.arrayOf(translationPropTypes).isRequired
        }).isRequired
    )
});

export const flattenedTranslatedCategoryTreeItemPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string,
    depth: PropTypes.number,
    quantity: PropTypes.number,
    parent: stringOrNumberPropTypes,
    status: PropTypes.number,
    orderIndex: PropTypes.number,
    metatags: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: stringOrNumberPropTypes,
            type: PropTypes.string,
            translations: PropTypes.arrayOf(translationPropTypes).isRequired
        }).isRequired
    )
});

export const coreUiColorsPropTypes = PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']);

export const featuresAttributesSearchConfigPropTypes = PropTypes.shape({
    countRows: PropTypes.bool,
    pageSize: PropTypes.number,
    curentPage: PropTypes.number,
    attributeName: PropTypes.string,
    categoryId: PropTypes.number,
    attributeType: stringOrNumberPropTypes,
    attributePresentation: stringOrNumberPropTypes
});

export const productsSearchConfigPropTypes = PropTypes.shape({
    countRows: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    curentPage: PropTypes.number.isRequired,
    productName: PropTypes.string.isRequired,
    catalogueIndex: PropTypes.string,
    tradeIndex: PropTypes.string,
    productId: stringOrNumberPropTypes,
    categoryId: stringOrNumberPropTypes,
    categoryTypeId: stringOrNumberPropTypes
});

export const translatedFeaturesAttributesListItemPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes.isRequired,
    translatedName: PropTypes.string,
    attributeType: stringOrNumberPropTypes.isRequired,
    attributePresentation: stringOrNumberPropTypes.isRequired,
    translatedAttributesCategories: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
});

export const productListItemPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    productImage: PropTypes.string,
    productImageAlternativeText: PropTypes.string,
    productTranslatedName: PropTypes.string,
    catalogueIndex: PropTypes.string,
    tradeIndex: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.arrayOf(translationPropTypes))
});

export const otherServicesListItemPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    productTranslatedName: PropTypes.string,
    catalogueIndex: PropTypes.string,
    tradeIndex: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.arrayOf(translationPropTypes))
});

export const productPricesTranslationsPropTypes = PropTypes.shape({
    inputs: PropTypes.shape({
        netPrice: PropTypes.string,
        grossPrice: PropTypes.string,
        tax: PropTypes.string,
        discountRate: PropTypes.string,
        exchangeRate: PropTypes.string,
        dateFrom: PropTypes.string,
        dateTo: PropTypes.string
    }),
    checkboxes: PropTypes.shape({
        undefined: PropTypes.string,
        precentageDiscount: PropTypes.string,
        definedPrice: PropTypes.string,
        exchangeRateTable: PropTypes.string,
        constantRate: PropTypes.string
    }),
    title: PropTypes.string,
    basePrice: PropTypes.string,
    promoPrice: PropTypes.string
});

export const productPricePropTypes = PropTypes.shape({
    priceId: stringOrNumberPropTypes,
    tax: stringOrNumberPropTypes,
    netPrice: stringOrNumberPropTypes,
    grossPrice: stringOrNumberPropTypes,
    dateTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    dateFrom: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    rate: stringOrNumberPropTypes,
    priceTypeId: stringOrNumberPropTypes
});

export const attributeValueSharedPropTypes = PropTypes.shape({
    /**
     * shared types: Color, Dictionary
     */
    attributeId: stringOrNumberPropTypes.isRequired,
    id: stringOrNumberPropTypes.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.arrayOf(translationPropTypes)
});

export const attributeSettingsSharedPropTypes = PropTypes.shape({
    attributeId: stringOrNumberPropTypes.isRequired,
    id: stringOrNumberPropTypes,
    tag: PropTypes.oneOf([ ...Object.values(attributeSettingsTagEnum)]),
    boolValue: PropTypes.bool,
    bigIntValue: PropTypes.number,
    dateTimeValue: PropTypes.string,
    stringValues: PropTypes.arrayOf(translationPropTypes)
});

export const attributeToEditPropTypes = PropTypes.shape({
    attributeId: stringOrNumberPropTypes,
    attributeAffiliation: PropTypes.string.isRequired,
    attributeType: PropTypes.string.isRequired,
    attributePresentation: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isFilter: PropTypes.bool,
    isMultiselect: PropTypes.bool,
    name: PropTypes.arrayOf(translationPropTypes),
    nameExtension: PropTypes.arrayOf(translationPropTypes)
});

export const photoWithTranslatedAlternativeTextPropTypes = PropTypes.shape({
    productId: stringOrNumberPropTypes,
    productImageId: stringOrNumberPropTypes,
    source: PropTypes.string,
    isDefault: PropTypes.bool,
    alternativeText: PropTypes.string,
    imageAlignments: imageAlignmentsPropTypes,
    translations: PropTypes.arrayOf(translationPropTypes),
    translatedAlternativeText: PropTypes.string
});

export const manufacturerPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string,
    logo: PropTypes.string
});

export const agreementPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string,
    isRequired: PropTypes.bool,
    isActive: PropTypes.bool,
    agreementTypeId: PropTypes.number,
    agreementType: PropTypes.string,
    regions: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(translationPropTypes)
});

export const currencyPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes.isRequired,
    name: PropTypes.string,
    shortName: PropTypes.string,
    regions: PropTypes.arrayOf(PropTypes.shape({
        id: stringOrNumberPropTypes,
        name: PropTypes.string
    })),
    active: PropTypes.bool
});

export const countryPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes.isRequired,
    name: PropTypes.string,
    iso: PropTypes.string
});

export const inputProps = PropTypes.shape({
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    plaintext: PropTypes.bool,
    addon: PropTypes.bool,
    className: PropTypes.string
});

export const attributeTranslatedSharedValuePropTypes = PropTypes.shape({
    attributeId: stringOrNumberPropTypes,
    id: stringOrNumberPropTypes,
    value: PropTypes.string,
    translatedName: PropTypes.string
});

export const attributeSharedValuePropTypes = PropTypes.shape({
    attributeId: stringOrNumberPropTypes,
    id: stringOrNumberPropTypes,
    value: PropTypes.string,
    translations: PropTypes.arrayOf(translationPropTypes)
});

export const regionPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes.isRequired,
    name: PropTypes.string,
    numberOfCountries: PropTypes.number
});

export const exchangeRatePropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes.isRequired,
    currencyId1: stringOrNumberPropTypes,
    currency1: PropTypes.string,
    currencyId2: stringOrNumberPropTypes,
    currency2: PropTypes.string,
    rate: PropTypes.number,
    dateFrom: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    dateTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
});

export const discountPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string,
    code: PropTypes.string,
    validFrom: PropTypes.string,
    validTo: PropTypes.string,
    priority: PropTypes.number,
    currencyId: stringOrNumberPropTypes,
    currency: PropTypes.string,
    discountTypeId: stringOrNumberPropTypes,
    discountType: PropTypes.string,
    discountRangeId: stringOrNumberPropTypes,
    discountRange: PropTypes.string,
    status: PropTypes.string,
    numberOfCodes: PropTypes.number,
    numberOfUses: PropTypes.number
});

export const deleteModalPropTypes = PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    confirm: PropTypes.string,
    cancel: PropTypes.string
});

export const dropdownStatusPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    label: PropTypes.string,
    color: PropTypes.oneOfType([coreUiColorsPropTypes, PropTypes.string])
});

export const productTablePropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    productImage: PropTypes.string,
    tradeIndex: PropTypes.string,
    catalogueIndex: PropTypes.string,
    translation: PropTypes.string
});

export const metatagsPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        type: PropTypes.string,
        translations: PropTypes.arrayOf(translationPropTypes)
    })
);

export const customPagePropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.arrayOf(
        PropTypes.shape({
            iso: PropTypes.string,
            value: PropTypes.string
        })
    ),
    content: PropTypes.arrayOf(translationPropTypes),
    metatags: metatagsPropTypes,
    slug: PropTypes.string,
    status: PropTypes.number,
    featured: PropTypes.bool,
    translatedName: PropTypes.string
});

export const orderAttachmentPropTypes = PropTypes.shape({
    href: PropTypes.string,
    filename: PropTypes.string,
    created: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ])
});

export const orderPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    number: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    currency: PropTypes.string,
    grossValue: PropTypes.number,
    netValue: PropTypes.number,
    status: PropTypes.number,
    attachement: orderAttachmentPropTypes,
    attributes: PropTypes.arrayOf(
        PropTypes.string
    )
});

export const statusConfigPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        id: stringOrNumberPropTypes,
        color: PropTypes.string,
        label: PropTypes.string,
        description: PropTypes.string
    })
);

export const dropDownPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: PropTypes.string
    })
);

export const languagesAvailableInStorePropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        iso: PropTypes.string,
        nativeName: PropTypes.string,
        englishName: PropTypes.string
    })
);

export const expectedLanguages = PropTypes.oneOf(["Pl", "En", "Ru", "Ua", "Uk"]);

export const orderConfigsPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        id: stringOrNumberPropTypes,
        name: PropTypes.arrayOf(PropTypes.shape({
            iso: expectedLanguages,
            value: PropTypes.string
        }))
    })
);

export const orderDetailsPropTypes = PropTypes.shape({
    number: stringOrNumberPropTypes,
    currency: PropTypes.string,
    paymentMethod: PropTypes.string,
    shippingMethod: PropTypes.string,
    shipmentAddressName: PropTypes.string,
    shipmentAddressSurname: PropTypes.string,
    shipmentAddressAddress: PropTypes.string,
    shipmentAddressZipCode: PropTypes.string,
    shipmentAddressCity: PropTypes.string,
    shipmentAddressCountry: PropTypes.string,
    shipmentAddressVoivodeship: PropTypes.string,
    shipmentAddressPhoneNumber: PropTypes.string,
    shipmentAddressEmail: PropTypes.string,
    shipmentAddressCountryId: stringOrNumberPropTypes,
    shipmentAddressVoivodeshipId: stringOrNumberPropTypes,
    orderStatus: PropTypes.string,
    orderStatusChangeDate: PropTypes.string,
    date: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    placingDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    fulfillmentDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    shipmentDate:PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    contrahentName: PropTypes.string,
    contrahentNumber: stringOrNumberPropTypes,
    invoiceNumber: stringOrNumberPropTypes,
    ean: stringOrNumberPropTypes,
    comment: PropTypes.string,
    additionalInfo: PropTypes.string,
    phone: stringOrNumberPropTypes,
    email: PropTypes.string,
    attachement: orderAttachmentPropTypes,
    invoice: orderAttachmentPropTypes,
    totalNetPrice: stringOrNumberPropTypes,
    totalGrossPrice: stringOrNumberPropTypes,
    returnNumber: stringOrNumberPropTypes,
    packageNumber: stringOrNumberPropTypes,
    totalWeight: stringOrNumberPropTypes,
    label: PropTypes.string,
    attributes: PropTypes.arrayOf(
        PropTypes.string
    )
});

export const productInOrderPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    photo: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    realized: PropTypes.number,
    grossPrice: stringOrNumberPropTypes,
    grossValue: stringOrNumberPropTypes
});

export const codesPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    code: PropTypes.string,
    usages: PropTypes.number,
    usagesLeft: PropTypes.number
});

export const groupPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string
});

export const userPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
});

export const usagesPropTypes = PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    date: PropTypes.string,
    orderId: stringOrNumberPropTypes,
    orderNumber: PropTypes.string
});

export const orderAttributesConfigPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        id: stringOrNumberPropTypes,
        label: PropTypes.string
    })
);

export const orderSortableColumnPropTypes = PropTypes.oneOf([
    null, 'date', 'contrahentName', 'totalGrossPrice', 'orderStatus'
]);

export const orderAdditionalFieldsPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        id: stringOrNumberPropTypes,
        tag: PropTypes.string,
        name: PropTypes.arrayOf(translationPropTypes)
    })
);

export const expectedActionsUserActionPropTypes = PropTypes.shape({
    tag: PropTypes.string,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    disabled: PropTypes.func,
    execute: PropTypes.func
});

export const expectedActionsPropTypes = PropTypes.arrayOf(
    PropTypes.oneOf([
        expectedActionsUserActionPropTypes,
        ...Object.values(actionsEnum)])
);

export const productAttributePropTypes = PropTypes.shape({
    attributeId: stringOrNumberPropTypes,
    attributeAffiliationId: stringOrNumberPropTypes,
    attributeTypeId: stringOrNumberPropTypes,
    attributeIsRequired: PropTypes.bool,
    attributeIsMultiselect: PropTypes.bool,
    attributeName: PropTypes.string,
    attributeNameExtension: PropTypes.string,
    dictionaryValues: PropTypes.arrayOf(
        PropTypes.shape({
            id: stringOrNumberPropTypes,
            name: PropTypes.string,
            selected: PropTypes.bool,
            colors: PropTypes.arrayOf(
                PropTypes.string
            )
        })
    ),
    bigIntValue: stringOrNumberPropTypes,
    decimalValue: stringOrNumberPropTypes,
    dateTimeValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ])
});

export const productAttributeToUpdatePropTypes = PropTypes.shape({
    productId: stringOrNumberPropTypes,
    attributeId: stringOrNumberPropTypes,
    dictionaryValues: PropTypes.arrayOf(stringOrNumberPropTypes),
    bigIntValue: stringOrNumberPropTypes,
    decimalValue: stringOrNumberPropTypes,
    dateTimeValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ])
});

export const homePageBannerImagePropTypes = PropTypes.shape({
    bannerImageId: stringOrNumberPropTypes,
    bannerid: stringOrNumberPropTypes,
    lp: stringOrNumberPropTypes,
    imageSource: PropTypes.string,
    imageAlternativeText: PropTypes.arrayOf(translationPropTypes),
    activeInLanguage: null,
    dateFrom: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    dateTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    link: PropTypes.string,
    active: PropTypes.bool
});

export const homePageBannerParamsPropTypes = PropTypes.shape({
    bannerId: stringOrNumberPropTypes,
    location: PropTypes.string,
    animationType: PropTypes.string,
    slideSpeed: stringOrNumberPropTypes,
    pauseOnHover: PropTypes.bool,
    enabled: PropTypes.bool,
    autoplay: PropTypes.bool
});

export const menuSectionPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    depth: PropTypes.number,
    status: PropTypes.number,
    parentId: stringOrNumberPropTypes,
    parentStatus: PropTypes.number,
    isContainer: PropTypes.bool,
    children: PropTypes.array
});

export const predefineSearchPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string
});

export const manufacturersForHomePageTablePropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    logo: PropTypes.string,
    name: PropTypes.string,
    active: PropTypes.bool
});

export const cookiesAgreementPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    header: PropTypes.string
});

export const orderStatusPropTypes = PropTypes.shape({
    id: stringOrNumberPropTypes,
    name: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string
});