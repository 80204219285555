import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { handleCheckboxAction } from '../../../../../utils/components';
import { withFormUtils } from '../../../../../hoc/withFormUtils';
import { generateInitialLabelsForSelectedAttributes } from '../../../../../utils/attributes';

import Dropdown from '../../../../../components/Dropdown';
import CalendarPicker from '../../../../../components/CalendarPicker';
import { Container, Input } from 'reactstrap';

import { attributeNumericTypeEnum } from '../../../../../enums/attributes';

import { productAttributePropTypes, stringOrNumberPropTypes, productAttributeToUpdatePropTypes, formUtilsHocPropTypes } from '../../../../../propTypes';

import styles from './index.module.scss';

const propTypes = {
    attribute: productAttributePropTypes,
    setEditedAttributes: PropTypes.func,
    productId: stringOrNumberPropTypes,
    editedAttributes: PropTypes.arrayOf(productAttributeToUpdatePropTypes),
    utils: formUtilsHocPropTypes,
    selectAttributeTranslation: PropTypes.string,
    emptyTranslation: PropTypes.string
};

const AttributeAssigner = ({
    attribute,
    setEditedAttributes,
    productId,
    editedAttributes,
    utils,
    selectAttributeTranslation,
    emptyTranslation,
    selectedFiltersValues,
    selectedTranslation,
}) => {
    const extractSelectedValuesIds = (values) => {
        const selectedValues = values.map(value => value.selected && value.id);
        return selectedValues.filter(value => value);
    };
    const extractSelectedFiltersIds = values => {
        const adjustedValues = values.find(value => value.attributeId === attribute.attributeId) || null;

        return adjustedValues ? adjustedValues.attributeValueIds : [];
    };

    const [ selectedAttributesValuesIds, setSelectedAttributesValuesIds ] = useState(
        productId
            ? extractSelectedValuesIds(attribute.dictionaryValues ? attribute.dictionaryValues : [])
            : extractSelectedFiltersIds(selectedFiltersValues ? selectedFiltersValues : [])
    );
    const [ currentSelectionLabel, setCurrentSelectionLabel ] = useState(generateInitialLabelsForSelectedAttributes(attribute, selectAttributeTranslation, emptyTranslation));
    const [ bigIntValue, setBigIntValue ] = useState(attribute.bigIntValue || '');
    const [ decimalValue, setDecimalValue ] = useState(attribute.decimalValue || '');
    const [ dateTimeValue, setDateTimeValue ] = useState(attribute.dateTimeValue || '');
    const [ currentColorSelection, setCurrentColorSelection ] = useState('');

    const isColorAttribute = isNaN(attribute.attributeTypeId) ? attribute.attributeTypeId === 'Color' : attributeNumericTypeEnum[attribute.attributeTypeId] === 'Color';

    const handleSelection = (id) => {
        setSelectedAttributesValuesIds(
            attribute.attributeIsMultiselect
                    ? selectedAttributesValuesIds
                    : [id]
        );

        if (attribute.attributeIsMultiselect) {
            handleCheckboxAction(id, selectedAttributesValuesIds, setSelectedAttributesValuesIds);
        }
    };

    const generateStepPrecisionString = (stepPrecision) => {
        return stepPrecision > 0 ? `0.${'0'.repeat(stepPrecision).replace(/.$/,'1')}` : '1';
    };
    const chooseInputForAttributeType = type => {
        const { settings } = attribute;
        switch (type) {
            case 'Tag':
            case 'Dictionary':
            case 'Type':
            case 'Color':
                return (
                    <Dropdown
                        withCheckboxes={attribute.attributeIsMultiselect}
                        withColors={isColorAttribute}
                        isDisabled={attribute.dictionaryValues.length < 1}
                        options={attribute.dictionaryValues}
                        currentSelectionLabel={currentSelectionLabel}
                        performSelection={handleSelection}
                        checkedIds={selectedAttributesValuesIds}
                        currentColorSelection={currentColorSelection}
                    />
            );
            case 'Decimal':
                return (
                    <Input
                        type='number'
                        value={decimalValue}
                        onChange={e => setDecimalValue(e.target.value)}
                        min={settings?.decimalRangeMinimumLimit}
                        max={settings?.decimalRangeMaximumLimit}
                        step={generateStepPrecisionString(settings?.decimalRangePrecision)}
                        invalid={decimalValue > settings?.decimalRangeMaximumLimit || decimalValue < settings?.decimalRangeMinimumLimit}
                    />
                );
            case 'Int':
                return (
                    <Input
                        type='number'
                        value={bigIntValue}
                        onChange={e => setBigIntValue(e.target.value)}
                        min={settings?.intRangeMinimumLimit}
                        max={settings?.intRangeMaximumLimit}
                        invalid={bigIntValue > settings?.intRangeMaximumLimit || bigIntValue < settings?.intRangeMinimumLimit}
                    />
                );
            case 'DateTime':
                return (
                    <CalendarPicker
                        value={dateTimeValue}
                        onChange={setDateTimeValue}
                        locale={utils.currentLanguage()}
                        minDateLimit={settings?.dateTimeRangeMinimumLimit}
                        maxDateLimit={settings?.dateTimeRangeMaximumLimit}
                    />
                );
            default: return null;
        }
    };

    const generateSelectionLabel = () => {
        if(attribute.attributeIsMultiselect && selectedAttributesValuesIds.length === 0) {
            setCurrentSelectionLabel(selectAttributeTranslation);
        } else if (attribute.attributeIsMultiselect && selectedAttributesValuesIds.length === 1) {
            setCurrentSelectionLabel(attribute.dictionaryValues.find(value => value.id === selectedAttributesValuesIds[0]).name);
        } else if (attribute.attributeIsMultiselect && selectedAttributesValuesIds.length > 1) {
            setCurrentSelectionLabel(`${selectedTranslation} ${selectedAttributesValuesIds.length} / ${attribute.dictionaryValues.length}`);
        }

        if (!attribute.attributeIsMultiselect && selectedAttributesValuesIds.length > 0) {
            setCurrentSelectionLabel(attribute.dictionaryValues.find(value => value.id === selectedAttributesValuesIds[0]).name);
        }

        if (isColorAttribute && selectedAttributesValuesIds.length > 0) {
            setCurrentColorSelection(
                attribute.dictionaryValues.find(value => value.id === selectedAttributesValuesIds[0]).colors
            );
        }
    };

    useEffect(() => {
        const updatedObject = {
            productId: productId && productId,
            attributeId: attribute.attributeId,
            dictionaryValues: selectedAttributesValuesIds,
            bigIntValue,
            decimalValue,
            dateTimeValue
        };

        const updatedAttributes = editedAttributes.map(editedAttribute => {
            if(editedAttribute.attributeId === updatedObject.attributeId) {
                return {
                    ...editedAttribute,
                    ...updatedObject,
                };
            }
            else {
                return editedAttribute;
            }
        });

        if (editedAttributes.length > 0) {
            setEditedAttributes(updatedAttributes);
        }
        else {
            setEditedAttributes(prevState => [...prevState, updatedObject]);
        }

        generateSelectionLabel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedAttributesValuesIds, bigIntValue, decimalValue, dateTimeValue]);

    return attribute ? (
        <Container className={clsx('my-3', styles.container)} fluid>
            <span className={clsx(styles.label)}>{attribute.attributeName} <span>{attribute.attributeNameExtension}</span></span>
            {
                chooseInputForAttributeType(isNaN(attribute.attributeTypeId) ? attribute.attributeTypeId : attributeNumericTypeEnum[attribute.attributeTypeId])
            }
        </Container>
    ) : null;

};

AttributeAssigner.propTypes = propTypes;

export default withFormUtils(AttributeAssigner);
