import React from 'react';
import ReactDOM from "react-dom";
import { connect, Provider } from 'react-redux';
import { store } from '../redux/store';
import IntlWrapper from '../IntlWrapper';

import { selectStoreAvailableIsoList, selectStoreCurrentLanguage, selectStoreCurrentLanguageId } from '../redux/selectors/storeLocale';
import { generateFormInitialValues, generateFormToSubmitValues } from "../utils/components";

import DynamicCategorySelectModal from '../components/modals/DynamicCategorySelectModal';
import DynamicErrorModal from '../components/modals/DynamicErrorModal';
import DynamicConfirmationModal from '../components/modals/DynamicConfirmationModal';

import { showToast } from '../redux/methods/toast';

export const withFormUtils = Component => {

    const Unauthenticated = ({ showToast, state, ...props }) => {

        const createDynamicModalContainer = () => {
            const el = document.getElementById('dynamicModalContainer');

            if (el) {
                el.remove();
            }

            const container = document.createElement('div');
            container.id = 'dynamicModalContainer';

            document.body.appendChild(container);

            return container;
        };

        const utils = function(state) {
            return {
                allLanguagesDetails: () => state.storeLocale.storeAvailableLanguages,
                allLanguages: () => selectStoreAvailableIsoList(state),
                currentLanguage: () => selectStoreCurrentLanguage(state),
                currentLanguageId: () => selectStoreCurrentLanguageId(state),
                generateFormInitialValues: (data, config) => generateFormInitialValues(data, config, selectStoreAvailableIsoList(state)),
                generateFormToSubmitValues: (data, config) => generateFormToSubmitValues(data, config, selectStoreAvailableIsoList(state)),
                selectCategory: (afterSelect, caption = null) => {
                    ReactDOM.render(
                        <Provider store={store}>
                            <IntlWrapper>
                                <DynamicCategorySelectModal
                                    categorySelect={afterSelect}
                                    caption={caption}
                                />
                            </IntlWrapper>
                        </Provider>,
                    createDynamicModalContainer());
                },
                confirm: (afterConfirm, header = null, caption = null) => {
                    ReactDOM.render(
                        <Provider store={store}>
                            <IntlWrapper>
                                <DynamicConfirmationModal
                                    afterConfirm={afterConfirm}
                                    header={header}
                                    caption={caption}
                                />
                            </IntlWrapper>
                        </Provider>,
                    createDynamicModalContainer());
                },
                error: (error = null) => {
                    ReactDOM.render(
                        <Provider store={store}>
                            <IntlWrapper>
                                <DynamicErrorModal
                                    error={error}
                                />
                            </IntlWrapper>
                        </Provider>,
                    createDynamicModalContainer());
                },
                toast: (message, type = 'success', duration = 4000) => {
                    showToast(message, type, duration);
                }
            };
        };

        return <Component utils={utils(state)} {...props} />;
    };

    Unauthenticated.getInitialProps = async (ctx) => {
        const props = Component.getInitialProps && await Component.getInitialProps(ctx);
        return { props };
    };

    const mapStateToProps = state => ({
        state: state
    });

    const mapDispatch = {
        showToast
    };

    return connect(mapStateToProps, mapDispatch)(Unauthenticated);
};