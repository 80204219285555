import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { goBackToGeneralSection } from '../../utils/components';

import { Row, Button } from 'reactstrap';

const propTypes = {
    formReset: PropTypes.func,
    onSubmit: PropTypes.func,
    history: PropTypes.object,
    isShowingOnlyIcons: PropTypes.bool,
    isSaveButtonDisabled: PropTypes.bool,
};

/**
 * This component is designed to be a part of form, therefore to abide to DRY, it's connected to intl
 */
const SectionEditFormButtons = ({ hideCancelButton = false, formReset, onSubmit, history, isShowingOnlyIcons = false, isSaveButtonDisabled = false }) => {
    const intl = useIntl();
    const translations = intl.messages.sharedComponents.sectionEditFormButtons;

    const handleCancelAction = () => {
        formReset();
        goBackToGeneralSection(history);
    };

    return (
        <Row className={clsx("d-flex flex-row justify-content-between mt-5", hideCancelButton ? "flex-row-reverse" : "flex-row")}>
            {
                !hideCancelButton &&
                    <Button type="button" onClick={handleCancelAction} color="danger">
                        <i className={clsx("fa fa-remove", !isShowingOnlyIcons && "mr-2")}/>
                        {
                            !isShowingOnlyIcons && <span>{translations.cancel}</span>
                        }
                    </Button>
            }
            <Button type={clsx(onSubmit ? 'button' : 'submit')} onClick={onSubmit} color="success" disabled={isSaveButtonDisabled}>
                <i className={clsx("fa fa-save", !isShowingOnlyIcons && "mr-2")}/>
                {
                    !isShowingOnlyIcons && <span>{translations.save}</span>
                }
            </Button>
        </Row>
    );
};

SectionEditFormButtons.propTypes = propTypes;

export default withRouter(SectionEditFormButtons);