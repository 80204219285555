const services = {
    searchForm: {
        serviceName: {
            label: 'Nazwa usługi:',
            placeholder: ''
        },
        action: 'SZUKAJ',
        reset: 'RESETUJ'
    },
    payments: {
        view: {
            title: 'Lista usług płatności',
            actions: {
                addService: 'DODAJ USŁUGĘ PŁATNOŚCI'
            },

            summaryProductModalLabel: 'Tworzenie artykułu zbiorczego',
            paginator: {
                previous: 'Poprzednia',
                next: 'Następna'
            },

            modal: {
                body: 'Wybierz kategorię dla której ma zostać utworzony produkt',
            },
            showCategoryTreeSelect: 'Pokaż filtr kategorii',
            hideCategoryTreeSelect: 'Ukryj filtr kategorii',
            categoryTree: {
                categoryTreeLabels: {
                    erpCategories: 'Kategorie ERP',
                    shopCategories: 'Kategorie Sklepowe'
                },
                generalCategoryLabel: 'Nazwa kategorii ogólnej',
                dropdownSelector: {
                    label: 'Widok kategorii',
                    options: {
                        all: 'Wszystkie',
                        active: 'Aktywne'
                    }
                },
            },
        },
        edit: {
            tabSelectorTitle: 'Sekcje',
            tabs: {
                basic: {
                    statusLabels: {
                        unpublished: 'Nieaktywny',
                        published: 'Aktywny',
                    },
                    title: 'Dane podstawowe',
                    modalHeader: 'Nazwa artykułu',
                    anyLabel: 'Dowolna',
                    buttons: {
                        seeDetails: 'Przejdź do artykułu',
                        defineFillers: 'Aktywuj reklamy uzupełniające',
                    },
                    inputs: {
                        status: 'Status',
                        statusErp: 'Status Erp',
                        productName: 'Nazwa główna',
                        nameCD: 'Nazwa CD',
                        nameFromERP: 'Nazwa z Erp',
                        syncErp: 'Synchronizuj nazwy z Erp',
                        isMasked: 'Stan maskowany',
                        booked: 'Ilość zarezerowana',
                        quantity: 'Ilość na stanie',
                        minQuantity: 'Ilość minimalna',
                        catalogueIndex: 'Indeks katalogowy',
                        tradeIndex: 'Indeks handlowy',
                        mainCategory: 'Kategoria dla cech *',
                        manufacturer: 'Producent *',
                        pkwiu: 'PKWiU',
                        swwku: 'SWW/KU',
                        localisation: 'Lokalizacja',
                        distinguish: 'Wyróżnik',
                        cnCode: 'Kod CN',
                        weight: 'Waga',
                        weightUnit: 'Nazwa jednostki wagi',
                        ean: 'Kod EAN',
                        kgo: 'Stawka KGO',
                        measure: 'Typ miary *',
                        mainMeasureUnit: 'Główna jednostka miary',
                        country: 'Kraj pochodzenia',
                        select: 'Wybierz',
                        manufacturerIndex: 'Indeks producenta',
                        originalName: 'Nazwa oryginalna',
                        includeMinQuantity: 'Uwzględnij minimalną ilość',
                        externalShortDescriptionSync: 'Zewnętrzna synchronizacja krótkiego opisu',
                        externalShortDescription: 'Zewnętrzny krótki opis',
                        externalLongDescriptionSync: 'Zewnętrzna synchronizacja długiego opisu',
                        externalLongDescription: 'Zewnętrzny długi opis',
                        includeReservations: 'Uwzględnij zarezerwowane',
                    },
                },
                description: {
                    title: 'Opis',
                    headers: {
                        short: 'Opis skrócony',
                        long: 'Opis pełny'
                    },
                    buttons: {
                        save: 'ZAPISZ ZMIANY',
                        cancel: 'ANULUJ ZMIANY',
                    }
                },
                photos: {
                    title: 'Zdjęcia',
                    addPhotoButton: 'DODAJ ZDJĘCIE',
                    deletePhotoButton: 'USUŃ ZAZNACZONE ZDJĘCIA',
                    cancel: 'ANULUJ ZMIANY',
                    save: 'ZAPISZ ZMIANY',
                    isDefaultText: 'Tak',
                    emptyAlternativeText: '[BRAK TŁUMACZENIA]',
                    emptyAlternativeName: '[BRAK NAZWY]',
                    emptyTranslation: 'Brak zdjęc do wyświetlenia',
                    imageAlignmentTranslations: {
                        horizontal: 'Poziomo',
                        vertical: 'Pionowo',
                    },
                    alignmentText: {
                        default: 'Domyślne',
                        modified: 'Zmodyfikowane',
                    },
                    columnNames: {
                        active: 'Aktywne',
                        order: 'Kolejność',
                        photo: 'Zdjęcie',
                        name: 'Nazwa',
                        alternativeText: 'Tekst alternatywny',
                        isDefault: 'Domyślne',
                        isDefaultDouble: 'Domyślne podwójny kafelek',
                        alignment: 'Wyrównanie',
                        actions: 'Akcje',
                    },
                    deleteModalTranslations: {
                        header: 'Usuwanie zdjęć',
                        body: 'Czy napewno chcesz usunąć zaznaczone zdjęcia?'
                    },
                    deleteSingleModalTranslations: {
                        header: 'Usuwanie zdjęcia',
                        body: 'Czy napewno chcesz usunąć zdjęcie?',
                        confirm: 'USUŃ',
                        cancel: 'ANULUJ',
                    },
                    tooltipTranslations: {
                        remove: 'Usuń zdjęcie',
                        edit: 'Edytuj zdjęcie',
                        showDetails: 'Pokaż przybliżenie',
                    },
                    editModal: {
                        buttons: {
                            confirm: 'ZAPISZ ZMIANY',
                            cancel: 'ANULUJ'
                        },
                        checkbox: 'Zdjęcie domyślne artykułu',
                        header: 'Edytuj zdjęcie',
                        inputWithFlagsLabel: 'Tekst alternatywny',
                        inputNameLabel: 'Nazwa',
                        horizontalAlignmentLabel: 'Wyrównanie w poziomie',
                        verticalAlignmentLabel: 'Wyrównanie w pionie',
                        tabTileName: 'Kafelek (podgląd)',
                        tabBannerName: 'Kafelek podwójny (podgląd)',
                        dropdown: {
                            center: 'Do środka',
                            bottom: 'Do dołu',
                            top: 'Do góry',
                            left: 'Do lewej',
                            right: 'Do prawej',
                        }
                    }
                },
                metaTags: {
                    title: 'Meta-tagi',
                },
                featuresAndAttributes: {
                    title: 'Cechy / atrybuty',
                    valuesTable: {
                        headers: {
                            name: 'Nazwa',
                            value: 'Wartość',
                            actions: 'Akcje'
                        },
                    },
                    editAttributes: 'Edytuj atrybuty produktu',
                    editFeatures: 'Edytuj cechy produktu',
                    labels: {
                        type: 'Typ atrybutu'
                    },
                    placeholders: {
                        type: 'Dowlony',
                        selectAttributes: 'Wybierz atrybuty',
                        selectFeatures: 'Wybierz cechy',
                        empty: 'Brak',
                        selected: 'Wybrano:'
                    },
                    tooltips: {
                        edit: 'Edit value',
                        add: 'Assign value',
                        remove: 'Unassign value'
                    },
                    attributeType: {
                        placeholder: 'Dowolny',
                        tag: 'Tag',
                        dictionary: 'Słownik',
                        int: 'Zakres liczbowy',
                        decimal: 'Zakres zmiennoprzecinkowy',
                        dateTime: 'Zakres dat',
                        color: 'Kolor',
                        boolean: 'Jedna z wartości: tak/nie',
                    },
                },
                prices: {
                    title: 'Ceny',
                    basePrice: 'Cena domyślna',
                    promoPrice: 'Cena promocyjna (zmiana trybu zeruje formularz ceny promocyjnej)',
                    nonBaseOption: 'Brak ceny odniesienia',
                    inputs: {
                        netPrice: 'Cena netto',
                        grossPrice: 'Cena brutto',
                        tax: 'Stawka VAT',
                        discountRate: 'Przecena',
                        exchangeRate: 'Kurs wymiany',
                        dateFrom: 'Ważna od',
                        dateTo: 'Ważna do',
                        afterPromo: 'Cena brutto po promocji',
                    },
                    checkboxes: {
                        undefined: 'Niezdefiniowana',
                        precentageDiscount: 'Rabat wyrażony w % w stosunku do ceny domyślnej',
                        definedPrice: 'Kwota zdefiniowana bezpośrednio',
                        exchangeRateTable: 'Cena przeliczana według tabeli kursów',
                        constantRate: 'Cena przeliczona według stałego kursu'
                    }
                },
                additionalCategories: {
                    title: 'Kategorie dodatkowe',
                    emptyTranslation: 'Nie znaleziono dodatkowych kategorii przypisanych do produktu',
                    columnNames: {
                        categories: 'Nazwa kategorii',
                        actions: 'Akcje',
                    },
                    buttons: {
                        delete: 'ODŁĄCZ ZAZNACZONE KATEGORIE',
                        add: 'PRZYPISZ KATEGORIE',
                        cancel: 'ANULUJ ZMIANY',
                    },
                    tooltipTranslation: 'Odłącz kategorie',
                    deleteModalTranslations: {
                        header: 'Odłączanie kategorii',
                        body: 'Czy napewno chcesz odłączyć wszystkie zaznaczone kategorie od wybranego produktu?',
                    },
                    deleteSingleModalTranslations: {
                        header: 'Odłączanie kategorii',
                        bodyStart: 'Czy chcesz odłączyć kategorię',
                        bodyEnd: 'od wybranego produktu',
                        confirm: 'ODŁĄCZ',
                        cancel: 'ANULUJ'
                    },
                    categorySelectionModal: {
                        body: 'Wybierz kategorię która ma zostać przypisana do wybranego produktu',
                    }
                },
                alternativeProducts: {
                    title: 'Zamienniki',
                    labels: {
                        isTwoWay: 'Dwustronna operacja'
                    },
                    tooltips: {
                        remove: 'Odłącz produkt',
                        edit: 'Edytuj',
                        isTwoWay: 'Przypisanie zamiennika artykułu A do artykułu B spowoduje, że artykuł B zostanie automatycznie zamiennikiem artykułu A.',
                        showDetails: 'Pokaż w sklepie',
                    },
                    empty: 'Brak produktów do wyświetlenia',
                    headers: {
                        photo: 'Zdjęcie',
                        name: 'Nazwa artykułu',
                        catalogueIndex: 'Indeks katalogowy',
                        tradeIndex: 'Indeks handlowy',
                        actions: 'Akcje',
                    },
                    deleteModal: {
                        header: 'Odłączanie zamiennika',
                        body: 'Czy jestes pewien, że chcesz odłączyć zamiennik?',
                        confirm: 'ODŁĄCZ',
                        cancel: 'ANULUJ'
                    },
                    deleteManyModal: {
                        header: 'Odłączanie zamienników',
                        body: 'Czy jestes pewien, że chcesz odłączyć wybrane zamienniki?'
                    },
                    buttons: {
                        delete: 'ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                        add: 'PRZYPISZ ARTYKUŁY',
                    },
                    fallbacks: {
                        name: '[BRAK NAZWY]',
                        catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                        tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
                    },
                },
                relatedProducts: {
                    title: 'Artykuł powiązane',
                    labels: {
                        isTwoWay: 'Dwustronna operacja'
                    },
                    tooltips: {
                        remove: 'Odłącz produkt',
                        edit: 'Edytuj',
                        isTwoWay: 'Powiązanie artykułu A z artykułem B spowoduje, że artykuł B zostanie automatycznie powiązany z artykułem A.'
                    },
                    empty: 'Brak produktów do wyświetlenia',
                    headers: {
                        photo: 'Zdjęcie',
                        name: 'Nazwa artykułu',
                        catalogueIndex: 'Indeks katalogowy',
                        tradeIndex: 'Indeks handlowy',
                        actions: 'Akcje',
                    },
                    deleteModal: {
                        header: 'Odłączanie artykułu powiązanego',
                        body: 'Czy jestes pewien, że chcesz odłączyć artykuł powiązany?',
                        confirm: 'ODŁĄCZ',
                        cancel: 'ANULUJ'
                    },
                    deleteManyModal: {
                        header: 'Odłączanie artykułów powiązanych',
                        body: 'Czy jestes pewien, że chcesz odłączyć wybrane artykuł powiązane?'
                    },
                    buttons: {
                        delete: 'ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                        add: 'PRZYPISZ ARTYKUŁY',
                    },
                    fallbacks: {
                        name: '[BRAK NAZWY]',
                        catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                        tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
                    },
                },
                opinions: {
                    title: 'Opinie',
                    removeTooltip: 'Usuń opinie',
                    emptyTable: 'Brak opinii',
                    deleteModal: {
                        header: 'Usuwanie opinii',
                        body: 'Czy na pewno chcesz usunąć opinię?',
                        confirm: 'USUŃ',
                        cancel: 'ANULUJ'
                    },
                    statuses: {
                        accepted: 'Zaakceptowana',
                        deleted: 'Usunięta',
                        notChecked: 'Niesprawdzona',
                    },
                    inputs: {
                        name: {
                            label: 'Nazwa',
                            filler: '[BRAK NAZWY]',
                        },
                        date: {
                            label: 'Data wystawienia',
                            filler: '[BRAK DATY]',
                        },
                        content: {
                            label: 'Treść',
                            filler: '[BRAK TREŚCI]',
                        },
                        isContentVisible: {
                            label: 'Treść widoczna',
                        },
                        rating: {
                            label: 'Ocena'
                        },
                        status: {
                            label: 'Status'
                        },
                        actions: {
                            label: 'Akcje'
                        }
                    },
                },
                delivery: {
                    title: 'Dostawa',
                    fields: {
                        deliveryCost: 'Koszt dostawy',
                        deliveryTime: 'Czas dostawy w dniach (0-365)'
                    },
                    fromErp: 'Pole zmapowane z Erp',
                },
                variants: {
                    title: 'Warianty',
                    selectAttribute: 'Wybierz atrybuty',
                    defaultSelect: 'Wybierz',
                    selectedAttribute: 'Wybrano: ',
                    variantSettingsHeadline: 'Ustawienia wariantów',
                    variantsTypes: {
                        size: 'Rozmiar',
                        color: 'Kolor',
                    },
                    settingsLabels: {
                        dispplayUnavailableVariants: 'Pokazuj artykuły niedostępne',
                        displayMinStateInfo: ' Wyświetlaj informacje o stanie minimalnym (Ostatnie sztuki!).'
                    },
                    varianceAttributes: 'Atrybuty wariantujące',
                    tooltips: {
                        remove: 'Odłącz wariant',
                        edit: 'Edytuj'
                    },
                    noAttributes: 'Brak atrybutów',
                    chooseProductsGuide: 'Wybierz warianty oraz produkt wzorcowy.',
                    noAttributesAlert: 'Nie znaleziono przypisanych atrybutów/cech dla danej kategorii. Przypisz atrybuty/cechy dla wybranej kategorii, aby kontynuwać tworzenie artykułu zbiorczego.',
                    empty: 'Brak produktów do wyświetlenia',
                    buttons: {
                        create: 'UTWÓRZ',
                        next: 'DALEJ',
                        prev: 'WRÓĆ',
                        delete: 'ODŁĄCZ ZAZNACZONE ARTYKUŁY',
                        add: 'PRZYPISZ ARTYKUŁY',
                    },
                    summaryProductSteps: {
                        chooseCategory: 'Wybierz kategorię, aby wyszukać przypisane do niej atrybuty wariantujace.',
                        chooseAttributes: 'Wybierz atrybuty wariantujace.',
                        chooseProducts: 'Wybierz warianty produktu głownego,',
                        chooseType: 'Wybierz typ wariantu',
                        chooseSettings: 'Wybierz ustawienia wyświetlania wariantów',
                        searchInAllCategories: 'Wyszukuj we wszystkich kategoriach'
                    },
                    headers: {
                        photo: 'Zdjęcie',
                        name: 'Nazwa artykułu',
                        catalogueIndex: 'Indeks katalogowy',
                        tradeIndex: 'Indeks handlowy',
                        actions: 'Akcje',
                    },
                    deleteModal: {
                        header: 'Odłączanie artykułu wariantującego',
                        body: 'Czy jestes pewien, że chcesz odłączyć artykuł wariantujący?',
                        confirm: 'ODŁĄCZ',
                        cancel: 'ANULUJ'
                    },
                    deleteManyModal: {
                        header: 'Odłączanie artykułów wariantujących',
                        body: 'Czy jestes pewien, że chcesz odłączyć wybrane artykuł wariantujący?'
                    },
                    fallbacks: {
                        name: '[BRAK NAZWY]',
                        catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                        tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
                    },
                }
            }
        },
        table: {
            headers: {
                name: 'Nazwa usługi płatności',
                actions: 'Akcje',
                active: 'Aktywna',
            },
            deleteModal: {
                header: 'Usuwanie usługi płatności',
                body: 'Czy jestes pewien, że chcesz usunąć usługę płatności',
                confirm: 'USUŃ',
                cancel: 'ANULUJ'
            },
            tooltips: {
                edit: 'Edytuj usługę płatności',
                remove: 'Usuń usługę płatności',
                add: 'DODAJ USŁUGĘ PŁATNOŚCI'
            },
            emptyTable: 'Nie znaleziono żadnych usług płatności',
            fallbacks: {
                name: '[BRAK NAZWY]'
            },
        },
    },
    shipments: {
        view: {
            title: 'Lista usług dostawy',
            actions: {
                addService: 'DODAJ USŁUGĘ DOSTAWY'
            },
        },
        table: {
            headers: {
                name: 'Nazwa usługi dostawy',
                actions: 'Akcje',
                active: 'Aktywna',
            },
            deleteModal: {
                header: 'Usuwanie usługi dostawy',
                body: 'Czy jestes pewien, że chcesz usunąć usługę dostawy',
                confirm: 'USUŃ',
                cancel: 'ANULUJ'
            },
            tooltips: {
                edit: 'Edytuj usługę dostawy',
                remove: 'Usuń usługę dostawy',
                add: 'DODAJ USŁUGĘ DOSTAWY'
            },
            emptyTable: 'Nie znaleziono żadnych usług dostawy',
            fallbacks: {
                name: '[BRAK NAZWY]'
            },
        },
    },
    list: {
        view: {
            title: 'Lista usług',
            paginator: {
                previous: 'Poprzednia',
                next: 'Następna'
            },
            actions: {
                addService: 'DODAJ USŁUGĘ',
                defineFillers: 'Aktywuj reklamy uzupełniające',
            },
            modal: {
                body: 'Wybierz kategorię dla której ma zostać utworzona usługa',
            },
            showCategoryTreeSelect: 'Pokaż filtr kategorii',
            hideCategoryTreeSelect: 'Ukryj filtr kategorii',
            categoryTree: {
                categoryTreeLabels: {
                    erpCategories: 'Kategorie ERP',
                    shopCategories: 'Kategorie Sklepowe'
                },
                generalCategoryLabel: 'Nazwa kategorii ogólnej',
                dropdownSelector: {
                    label: 'Widok kategorii',
                    options: {
                        all: 'Wszystkie',
                        active: 'Aktywne'
                    }
                },
            },
        },
        searchForm: {
            productName: {
                label: 'Nazwa usługi:',
                placeholder: ''
            },
            catalogueIndex: {
                label: 'Indeks katalogowy:',
                placeholder: ''
            },
            tradeIndex: {
                label: 'Indeks handlowy:',
                placeholder: ''
            },
            ean: {
                label: 'Kod kreskowy:',
                placeholder: ''
            },
            status: {
                label: 'Aktywność',
            },
            warning: {
                label: 'Zawierające uwagi'
            },
            active: 'Aktywne',
            inactive: 'Nieaktywne',
            action: 'SZUKAJ',
            reset: 'RESETUJ',
            defaultSearchLabel: 'Wszystkie'
        },
        table: {
            buttons: {
                activate: 'Aktywuj',
                deactivate: 'Dezaktywuj'
            },
            headers: {
                lp: 'Lp',
                name: 'Nazwa usługi',
                catalogueIndex: 'Indeks katalogowy',
                tradeIndex: 'Indeks handlowy',
                actions: 'Akcje',
                category: 'Kategoria dla cech',
                active: 'Aktywna',
                type: 'Typ'
            },
            deleteModal: {
                header: 'Usuwanie usługi',
                body: 'Czy jestes pewien, że chcesz usunąć usługę?',
                confirm: 'USUŃ',
                cancel: 'ANULUJ'
            },
            tooltips: {
                edit: 'Edytuj usługę',
                remove: 'Usuń usługę',
                add: 'DODAJ USŁUGĘ'
            },
            emptyTable: 'Nie znaleziono żadnych usług',
            fallbacks: {
                name: '[BRAK NAZWY]',
                catalogueIndex: '[BRAK INDEKSU KATALOGOWEGO]',
                tradeIndex: '[BRAK INDEKSU HANDLOWEGO]',
            },
        },
    }
};

export default services;