import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { addUpdatePageSection } from '../../../../redux/methods/homePage';
import { generateNonIdsOptionsForDropdown } from '../../../../utils/components';

import { Form as FinalForm } from 'react-final-form';
import { Form, Row } from 'reactstrap';

import SectionAsideButtons from '../../../../components/SectionAsideButtons';
import FormFieldTextOrNumber from '../../../../components/formFields/FormFieldTextOrNumber';
import HomePageSectionMainData from '../HomePageSectionMainData';

import { withFormUtils } from '../../../../hoc/withFormUtils';

import FormFieldWithCheckbox from '../../../../components/formFields/FormFieldWithCheckbox';
import FormFieldWithDropdown from '../../../../components/formFields/FormFieldWithDropdown';
import FormFieldWithRange from '../../../../components/formFields/FormFieldWithRange';

import styles from './index.module.scss';

import Tabulator from '../../../../components/Tabulator';

const propTypes = {
    addUpdatePageSection: PropTypes.func,
    section: PropTypes.object
};

const AnimatedBannerSection = ({ utils, section, addUpdatePageSection, sectionLocation }) => {
    const history = useHistory();
    const intl = useIntl();
    const translations = intl.messages.animatedBanner.settings;
    const translationsHomePage = intl.messages.homePage;

    const config = { translations: ['title'], date: ['activeFrom', 'activeTo'] };

    const handleOnSubmit = async formData => {
        const response = await addUpdatePageSection(utils.generateFormToSubmitValues(formData, config));

        if(typeof response === 'number') {
            history.push(`edit?sectionID=${response}&sectionType=${section.type}&location=${sectionLocation}`);

            return;
        }

        return response;
    };

    const animationTypes = ['fade', 'standard'];
    const animationOptions = generateNonIdsOptionsForDropdown(animationTypes, translations.animations);

    return section ? (
        <div className={styles.fullScreenOnMobile}>
            <div className='animated fadeIn'>
                <FinalForm
                    initialValues={utils.generateFormInitialValues(section, config)}
                    onSubmit={handleOnSubmit}
                    render={({ handleSubmit, values, form }) => {
                        const currentSelectedAnimation = animationOptions.find(animation => animation.id === values.animationType);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Tabulator
                                    listOfElements={[
                                        {
                                            label: translationsHomePage.tabs.basicData,
                                            id: 'searchTerms-table',
                                            component: <HomePageSectionMainData
                                                isSectionHeightController={true}
                                                isNumberOfTilesController={false}
                                                form={form} />
                                        },
                                        {
                                            label: translationsHomePage.tabs.settings,
                                            id: 'lastPhrases-table',
                                            component:
                                                <React.Fragment>
                                                    <Row className='mb-3 px-3'>
                                                        <FormFieldWithCheckbox
                                                            name='autoPlay'
                                                            label={translations.inputs.autoPlay}
                                                        />
                                                    </Row>
                                                    <Row className='mb-3 px-3'>
                                                        <FormFieldTextOrNumber
                                                            className='d-block'
                                                            label={translations.inputs.autoPlayDuration}
                                                            min={1}
                                                            max={10}
                                                            type='number'
                                                            name='slideSpeed'
                                                            disabled={!values.autoPlay}
                                                        />
                                                    </Row>
                                                    <Row className='mb-3 px-3'>
                                                        <FormFieldWithCheckbox
                                                            name='pauseOnHover'
                                                            label={translations.inputs.pauseOnHover}
                                                        />
                                                    </Row>
                                                    <Row className='mb-3 px-3'>
                                                        <div className={styles.dropdown}>
                                                            <FormFieldWithDropdown
                                                                label={translations.inputs.animationType}
                                                                name='animationType'
                                                                options={animationOptions}
                                                                currentSelectionLabel={currentSelectedAnimation ? currentSelectedAnimation.label : translations.inputs.animationFallback}
                                                            />
                                                        </div>
                                                    </Row>
                                                    <Row className='mb-3 px-3 w-50'>
                                                        <FormFieldWithRange
                                                            name='borderRadius'
                                                            label={translations.inputs.borderRadius}
                                                            max={50}
                                                        />
                                                    </Row>
                                                </React.Fragment>
                                        },
                                    ]}
                                />
                                <SectionAsideButtons
                                    buttons={{
                                        add: {
                                            color: 'success',
                                            label: translations.button.save,
                                            action: handleSubmit,
                                            iconClass: 'fa fa-save mr-2',
                                        }
                                    }}
                                />
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    ) : null;
};

AnimatedBannerSection.propTypes = propTypes;

const mapDispatch = {
    addUpdatePageSection,
};

export default withFormUtils(connect(null, mapDispatch)(AnimatedBannerSection));