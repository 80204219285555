const sections = {
    productCardSections: {
        title: 'Sekcje w karcie produktu',
        table: {
            headers: {
                order: 'Kolejność',
                name: 'Nazwa',
                type: 'Typ sekcji',
                desktopActive: 'Aktywny na desktopie',
                mobileActive: 'Aktywny na telefonie',
                activeFrom: 'Aktwyna od',
                activeTo: 'Aktywna do',
                activeInLanguages: 'Aktywna w językach',
                actions: 'Akcje',
                categories: 'Przypisane kategorie'
            },
            emptyTable: 'Nie znaleziono sekcji.',
            tooltips: {
                edit: 'Edytuj sekcję',
                remove: 'Usuń sekcję',
            },
            deleteModa: {
                header: 'Usuń sekcję',
                body: 'Czy napewno chcesz usunąć sekcję?',
                confirm: 'USUŃ',
                cancel: 'WRÓĆ'
            }
        },
        edit: {
            tabs: {
                sectionEdit: {
                    title: 'Edycja sekcji',
                    inputs: {
                        isActive: 'Aktywność',
                        header: 'Nagłówek sekcji',
                        content: 'Treść sekcji',
                        button: 'Treść buttona',
                        link: 'Link',
                        linkHelper: 'Aby link był dostępny, musisz podać treść przycisku.',
                        photoLocation: 'Lokalizacja zdjęcia',
                        backgroundColor: 'Kolor tła',
                        shadeColor: 'Kolor przesłony',
                        backgroundColorButton: 'Wybierz',
                        isFullWidth: 'Tło na szerokość okna przeglądarki',
                        buttonFontColor: 'Kolor fontu przycisku',
                        buttonColor: 'Kolor tła przycisku',
                        opacityRange: 'Stopień przyciemnienia',
                        manufacturer: 'Producent',
                        tag: 'Tag',
                        category: 'Kategoria',
                        selectCategory: 'Wybierz kategorię',
                        changeCategory: 'Zmień kategorię',
                        selectNone: 'Brak',
                    },
                    charLimitExceeded: 'Ilość znaków została przekroczona. Zmniejsz ilość znaków aby kontynuować',
                    button: 'ZAPISZ ZMIANY',
                },
                categories: {
                    title: 'Przypisane kategorie',
                    table: {
                        columnNames: {
                            categories: 'Przypisana kategoria',
                            actions: 'Akcje',
                        },
                        tooltip: 'Odłącz kategorię',
                        placeholder: 'Nie znaleziono kategorii przypisanych do wybranej sekcji',
                        deleteModal: {
                            header: 'Odłącz kategorię',
                            bodyStart: 'Czy chcesz odłączyć kategorię',
                            bodyEnd: 'od wybranej sekcji',
                            confirm: 'ODŁĄCZ',
                            cancel: 'WRÓĆ'
                        }
                    },
                    controls: {
                        add: 'PRZYPISZ KATEGORIĘ',
                        remove: 'ODŁĄCZ WYBRANE KATEGORIE'
                    },
                    additionModal: {
                        header: 'Przypisz wybraną kategorię',
                        body: 'Czy przypisać wybraną kategorię do sekcji',
                        confirm: 'PRZYPISZ',
                        cancel: 'WRÓĆ'
                    },
                    deletionModal: {
                        header: 'Odłącz wybrane kategorię',
                        body: 'Czy odłączyć wybrane kategorie od sekcji',
                    },
                    categorySelectionModal: {
                        header: 'Wybór kategorii',
                        body: 'Wybierz kategorię, która ma być przypisana do obecnej sekcji',
                        emptySelectionLabel: 'Wybierz kategorię',
                        confirm: 'WYBIERZ',
                        cancel: 'WRÓĆ'
                    },
                    nameFiller: '[BRAK NAZWY]',
                    targetCategoryError: 'Wybrana kategoria już jest przypisana do obecnej sekcji, wybierz inną kategorię.'
                },
            }
        }
    }
};

export default sections;